import "react-app-polyfill/stable";
import * as ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {createTheme, CssBaseline, ThemeProvider} from "@mui/material";
import {BrowserRouter} from "react-router-dom";
import {grey} from "@mui/material/colors";
import {CartProvider} from "use-shopping-cart";

declare module "@mui/material/Alert" {
    interface AlertPropsColorOverrides {
      primary: true;
    }
}

export const PRIMARY_COLOR = "#57F0A7";

const theme = createTheme({
    palette: {
        mode: "dark",
        text: {
            primary: "#fff",
            secondary: grey[500],
        },
        primary: {
            main: PRIMARY_COLOR,
        },
        background: {
            default: "black",
        },
    },
    components: {
        MuiTextField: {
            defaultProps: {
                size: "small",
            },
            styleOverrides: {
                root: {
                    "& .MuiFilledInput-root": {
                        background: "rgb(226, 183, 41)",
                    },
                },
            },
        },
        MuiButton: {
            defaultProps: {
                variant: "outlined",
            },
            styleOverrides: {
                root: {
                    "width": "max-content",
                    "padding": "5px 10px",
                    "&:not(:last-child)": {
                        marginRight: "7px",
                    },
                    "height": "max-content",
                },

            },
        },
        MuiGrid: {
            defaultProps: {
                spacing: 2,
            },
            styleOverrides: {
                container: {
                    "maxWidth": "100%",
                    "&.MuiGrid-container": {
                        maxWidth: "inherit",
                        width: "calc(100% + 16px)",
                        marginLeft: "-16px",
                    },
                },
            },
        },
    },
    typography: {
        fontFamily: "helvetica",
        button: {
            textTransform: "none",
        },
    },
});

const el = document.getElementById("root");


if (el) {
    const root = ReactDOM.createRoot(el);
    root.render(
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <BrowserRouter>
                <CartProvider
                    shouldPersist
                    cartMode="checkout-session"
                    stripe={"pk_live_51OT4MdEO4uVGvLqo7CbJQBwe8mjMBiEAqmNBWy3haWD33bnt6yyMVuPiucitHzrjiblAky1VZEqMiPXzzhQJDEDE00nCFZooNO"}
                    currency="GBP">
                    <App />
                </CartProvider>
            </BrowserRouter>
        </ThemeProvider>
    );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
