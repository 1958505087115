import {Link, Stack} from "@mui/material";
import React, {useState, ReactNode, forwardRef, useImperativeHandle, Children} from "react";
import {LoadingButton, ResponseObject} from "./LoadingButton";


interface Params {
    onSubmit?: (e:{[key:string]:unknown}) => Promise<any> | void,
    errorStyle?: object,
    children?: ReactNode,
    className?: string,
    sx?: object,
    formRef?: React.RefObject<HTMLFormElement>,
    showError?: boolean,
    onError?: (e: string) => void,
    onBackPress?: () => void,
    noValidate?: boolean,
    submitText?: string,
    button?: boolean,
    disabled?: boolean,
    functionType?: "async"|"sync"
}

const Form = forwardRef(({onSubmit, functionType="async", children, className, sx, formRef, onError, noValidate, submitText, onBackPress, button=true, disabled=false}:Params, ref) => {
    const [buttonState, setButtonState] = useState<"normal"|"loading"|"success"|ResponseObject>("normal");

    useImperativeHandle(ref, () => ({
        resetButton() {
            setButtonState("normal");
        },
    }));

    const handleSubmit = async (e: any) => {
        setButtonState("loading");
        e.preventDefault();
        e.stopPropagation();

        const formData: {[k: string]: any} = {};


        for (let i = 0; i < e.target.length; i++) {
            const element = e.target[i];
            if (!noValidate && (!element.validity.valid || (element.getAttribute("require") && element.value === ""))) {
                setButtonState(new Error("Please fill in the required fields."));
                onError && onError("Please fill in the required fields.");
                return;
            } else {
                if (element.name === "") {
                    continue;
                }

                if (element.type === "checkbox") {
                    formData[element.name] = element.checked;
                    continue;
                }

                formData[element.name] = element.value.trim();
            }
        }

        if (functionType === "sync") {
            try {
                const response = onSubmit && await onSubmit(formData);
                setButtonState(response || "normal");
                return;
            } catch {
                setButtonState(new Error("Please fill in the required fields."));
                return;
            }
        }

        onSubmit && onSubmit(formData)?.then((message="success") => setButtonState(message)).catch((e) => {
            setButtonState(e);
            return;
        });
    };

    const addDisabledState = (children:ReactNode):ReactNode => {
        return Children.map(children, (child) => {
            if (!React.isValidElement(child)) {
                return child;
            }

            const childzFurtherChildren = child.props.children ?
                addDisabledState(child.props.children) :
                undefined;

            const props = {disabled: buttonState !== "normal" || disabled || child.props.disabled};

            return childzFurtherChildren ?
                React.cloneElement(child, props, childzFurtherChildren) :
                React.cloneElement(child, props);
        });
    };

    return (
        <form onSubmit={handleSubmit} className={className} style={{paddingTop: "10px", ...sx}} noValidate ref={formRef}>
            <Stack spacing={2}>
                <>
                    {addDisabledState(children)}
                    {onBackPress ?
                        <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                            <Link onClick={onBackPress}>Back</Link>
                            {button && <LoadingButton width="max-content" variant="contained" key={"loadingButton"} resetButton={() => setButtonState("normal")} disabled={disabled} type={"submit"} state={buttonState} text={submitText}/>}
                        </Stack> :
                        button && <LoadingButton sx={{minWidth: "100px"}} variant="contained" key={"loadingButton"} resetButton={() => setButtonState("normal")} disabled={disabled} type={"submit"} state={buttonState} text={submitText}/>
                    }
                </>
            </Stack>
        </form>
    );
});

export default Form;
