import Page from "../Components/Page";
import {Box, Button, Grid, Stack, Typography} from "@mui/material";
import {Link, useNavigate, useParams} from "react-router-dom";
import {PRIMARY_COLOR} from "..";
import {useContext} from "react";
import {PublicContext} from "../App";
import {HoverItem, formatPrice} from "../Util/util";
import AddToCart from "../Components/AddToCart";
import {MerchItem} from "../Util/typeDefinitions";


export default function Store() {
    const navigate = useNavigate();
    const {type}:{type?: "music"|"merch"} = useParams();

    return (
        <Page metaTitle="OMA | Merch" title="STORE" subtitle={
            <Stack direction={"row"} spacing={1} mt={3}>
                <Link to={"/store"} style={{color: !type ? PRIMARY_COLOR : "white"}}><Typography variant="h4">All</Typography></Link>
                <Typography variant="h4">|</Typography>
                <Link to={"/store/merch"} style={{color: type === "merch" ? PRIMARY_COLOR : "white"}}><Typography variant="h4">Merch</Typography></Link>
                <Typography variant="h4">|</Typography>
                <Link to={"/store/music"} style={{color: type === "music" ? PRIMARY_COLOR : "white"}}><Typography variant="h4">Music</Typography></Link>
            </Stack>
        } fullHeight>
            {false && <Stack direction={"row"} width={"max-content"} m={"0 auto !important"} pt={"40px"}>
                <Typography onClick={() => navigate("/store/merch")} sx={{"cursor": "pointer", "&:hover": {textDecoration: "underline"}}} variant="h4" color={type !== "music" ? PRIMARY_COLOR : undefined}>Merch</Typography>
                <Typography variant="h4">&emsp;|&emsp;</Typography>
                <Typography onClick={() => navigate("/store/music")} sx={{"cursor": "pointer", "&:hover": {textDecoration: "underline"}}} variant="h4" color={type === "music" ? PRIMARY_COLOR : undefined}>Music</Typography>
            </Stack>}
            <RenderStoreItems type={type}/>
        </Page>
    );
}


export function RenderStoreItems({type}:{type?:"merch"|"music"}) {
    const navigate = useNavigate();

    const music = Object.entries(useContext(PublicContext).music);
    const merch = Object.entries(useContext(PublicContext).merch);

    const allItems = [...merch, ...music];

    // Get all items
    // For any that have no variants, just add them with their image, stock and price.
    // Individual items, when added to basket just show a summary popup.

    // For those with colors, add them as separate items

    const itemsToRender = (type === "merch" ? merch : type === "music" ? music : allItems)
        .sort(([, itemA], [, itemB]) => ((itemA.position || 10000) - (itemB.position || 10000)) || ((itemA.created < itemB.created) ? 1 : ((itemA.created > itemB.created) ? -1 : 0)));

    return (
        <Grid container overflow={"visible"} pb={10}>
            {itemsToRender.map(([id, item]) => {
                const type = item.id.split("-")[0];
                console.log("ID", type);
                return (<HoverItem item xs={12} sm={6} md={4} lg={3} position={"relative"} onClick={() => navigate(`/${type}/${id}`)}>
                    <Box display={"flex"} p={1} alignItems={"center"} flexDirection={"column"} sx={{height: "100%", overflow: "hidden"}}>
                        <Box width={290} height={"300px"} sx={{backgroundImage: `url(${item.image})`, backgroundRepeat: "no-repeat", backgroundSize: "contain", backgroundPosition: "50%"}} alignSelf={"center"}/>
                        <Stack width={350} maxWidth={"100%"} sx={{padding: 2, flex: 1}} spacing={1}>
                            <Stack>
                                <Typography variant="h6">{item.name}</Typography>
                                {type === "merch" && (item as MerchItem).sizes && <Stack direction={"row"} spacing={1} flex={1}>
                                    {Object.values((item as MerchItem).sizes || {}).sort((sizeA, sizeB) => ["XXS", "XS", "S", "M", "L", "XL", "XXL", "XXXL"].indexOf(sizeA.name) - ["XXS", "XS", "S", "M", "L", "XL", "XXL", "XXXL"].indexOf(sizeB.name))
                                        .map((sizeItem) =>
                                            <Box onClick={() => sizeItem.stock > 0 ? navigate(`/merch/${id}/${sizeItem.name}`) : undefined} alignContent={"center"} textAlign={"center"} height={"30px"} p={1} minWidth={sizeItem.name.length > 1 ? undefined : "30px"} border={"1px grey solid"} sx={{opacity: sizeItem.stock > 0 ? 1 : 0.5, cursor: "pointer"}}>
                                                <Typography textAlign={"center"} lineHeight={"15px"}>
                                                    {sizeItem.name}
                                                </Typography>
                                            </Box>)}
                                </Stack>}
                            </Stack>
                            <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                                <Typography fontSize={"1.3em"}>{formatPrice(item.price)}</Typography>
                                {type === "music" ? <AddToCart item={item}/> : <Button onClick={() => navigate(`/${type}/${id}`)}>View item</Button>}
                            </Stack>
                        </Stack>
                    </Box>
                </HoverItem>);
            })}
        </Grid>
    );
}
