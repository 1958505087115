import {ChangeEvent, useEffect, useState} from "react";
import Form from "../../../Components/Form";
import {MerchItem, MerchSize, SizeNames} from "../../../Util/typeDefinitions";
import FileDropzone from "../../../Components/FileDropzone";
import {Button, Divider, Grid, List, ListItem, ListItemSecondaryAction, ListItemText, MenuItem, Stack, Switch, Table, TableCell, TableRow, Typography} from "@mui/material";
import InputGroup from "../../../Components/FormComponents/InputGroup";
import {addDoc, arrayRemove, collection, doc, updateDoc} from "firebase/firestore";
import {db, storage} from "../../../Util/firebase";
import {uploadFile} from "../../../Util/util";
import {Popup} from "../../../Components/Popup";
import IconButtonPop from "../../../Components/IconButtonPop";
import {AddCircleOutline, Delete, RemoveCircleOutline} from "@mui/icons-material";
import {deleteObject, ref} from "firebase/storage";
import {PRIMARY_COLOR} from "../../..";
import Dropdown from "../../../Components/FormComponents/Dropdown";

type Params = {
    id?: string,
    item?: MerchItem,
    onComplete?: (e?: MerchItem) => void,
}

const defaultMerchItem:MerchItem = {
    name: "",
    description: "",
    price: 0,
    stock: 0,
    published: false,
    id: "",
    created: "",
    sizes: {},
};

export default function UploadMerchForm({id, item, onComplete}:Params) {
    const [formData, setFormData] = useState<MerchItem>(item || defaultMerchItem);
    const [coverImageUploads, setCoverImageUploads] = useState<FileList|{name: string, url: string}[]|undefined>(item?.images);
    const [reorderImagesPopup, setReorderImagesPopup] = useState(false);

    useEffect(() => {
        if (id && item) {
            setFormData(item);
        }
    }, [item]);

    const handleFormSubmit = async () => {
        const uploadObject = {
            name: formData.name,
            description: formData.description,
            price: formData.price,
            sizes: Object.fromEntries(Object.values(formData.sizes).map((size) => ([size.name, size]))),
            position: formData.position,
            stock: formData.stock,
            sizing: formData.sizing,
            externalLink: formData.externalLink,
            published: false,
            created: (new Date()).toISOString(),
        } as MerchItem;
        console.log("Upload object", uploadObject);
        console.log("Upload cover", coverImageUploads);
        console.log("Upload sizes", formData.sizes);

        let itemId = id;
        if (!id) {
            itemId = (await addDoc(collection(db, "merch"), uploadObject)).id;
        }

        if (id) {
            await updateDoc(doc(db, "merch", id), uploadObject);
        }
        console.log("COVER" + coverImageUploads, itemId, typeof coverImageUploads?.[0] !== "string");

        const prevImageOrder = formData.imageOrder || [];

        if (coverImageUploads && itemId && !Array.isArray(coverImageUploads)) {
            const maxExistingImageName = prevImageOrder.length ? Math.max(...prevImageOrder) : 0;
            for (let i = 0; i < coverImageUploads.length; i++) {
                const file = coverImageUploads[i];
                console.log("Uploading image", file);
                if (typeof file === "string") continue;
                await uploadFile(["merch", itemId, `${i + maxExistingImageName + 1}.webp`], file);
                prevImageOrder.push(i + maxExistingImageName + 1);
            }
            await updateDoc(doc(db, "merch", itemId), {imageOrder: prevImageOrder});
        }

        if (formData.published && itemId) {
            // Check if all images uploaded.
            // Check all pricing etc
            // update

            if (Object.entries(formData?.sizes || {})?.length === 0) {
                throw new Error("Cannot publish without a size");
            }

            if (!coverImageUploads || coverImageUploads?.length === 0) {
                throw new Error("Cannot publish without an image");
            }
            if (!formData.price) {
                throw new Error("Cannot publish without an album price");
            }

            Object.values(formData.sizes || {}).map((size) => {
                if (!size.name) {
                    throw new Error("Cannot publish size without a name");
                }
                if (!size.stock) {
                    throw new Error("Cannot publish size without stock");
                }
            });

            await updateDoc(doc(db, "merch", itemId), {published: true});
        }

        onComplete && onComplete();
        return;
    };

    const reorderImages = async (e: {[key: string]: string}) => {
        if (!id) throw new Error("No id for item. Save and reload the page, then try again.");

        const songPositions = Object.values(e).reduce((acc, item: string) => {
            acc.push(item);
            return acc;
        }, [] as any[]);

        if (new Set(songPositions).size !== songPositions.length) {
            throw new Error("Duplicate positions.");
        }

        const orderedItems = Object.entries(e).sort(([, vA], [, vB]) => (parseInt(vA) > parseInt(vB)) ? 1 : ((parseInt(vA) < parseInt(vB)) ? -1 : 0)).map(([k]) => parseInt(k));

        await updateDoc(doc(db, "merch", id), {imageOrder: orderedItems});
        setReorderImagesPopup(false);
    };

    const onDeleteImage = async (name: number) => {
        if (!id) throw new Error("No id for item. Save and reload the page, then try again.");
        // Remove from file store
        const imageRef = ref(storage, `merch/${id}/${name}.webp`);
        await deleteObject(imageRef);

        await updateDoc(doc(db, "merch", id), {imageOrder: arrayRemove(name)});
        // Remove from image list
    };

    return (
        <Form onSubmit={handleFormSubmit}>
            {formData?.images ?
                <Stack alignItems={"center"}>
                    <Grid container width={"50%"}>
                        {formData?.images.map((image, index) => <Grid item xs={3}><img style={index === 0 ? {border: "black 1px solid", outline: `${PRIMARY_COLOR} 2px solid`} : {}} width={"100%"} src={image.url}/></Grid>)}
                    </Grid>
                    <Stack direction={"row"} alignItems={"center"} justifyContent={"center"} spacing={1}>
                        <FileDropzone multiple accept="image/webp" onChange={(e) => setCoverImageUploads(e.target.files || undefined)} label="Add images" name="images"/>
                        <Button onClick={() => setReorderImagesPopup(true)}>Reorder / remove images</Button>
                    </Stack>
                </Stack> : <FileDropzone multiple accept="image/webp" onChange={(e) => setCoverImageUploads(e.target.files || undefined)} label="Merch images" name="images"/>}
            <InputGroup value={formData?.name} onChange={(e: ChangeEvent<HTMLInputElement>) => setFormData((f) => ({...f, name: e.target.value}))} required name={"name"} label={"Item name"}/>

            <InputGroup value={formData?.description} onChange={(e: ChangeEvent<HTMLInputElement>) => setFormData((f) => ({...f, description: e.target.value}))} name={"description"} label={"Description"}/>
            <InputGroup value={formData?.price} required name={"price"} onChange={(e: ChangeEvent<HTMLInputElement>) => setFormData((f) => ({...f, price: parseInt(e.target.value)}))} label={"Price (Pence)"} type={"number"} placeholder={"Type 1500 for £15.00"}/>
            <InputGroup value={formData?.sizing} name={"sizing"} onChange={(e: ChangeEvent<HTMLInputElement>) => setFormData((f) => ({...f, sizing: e.target.value}))} label={"Sizing"} placeholder={"\"Order a size larger than normal.\""}/>
            <InputGroup value={formData?.position} required name={"position"} onChange={(e: ChangeEvent<HTMLInputElement>) => setFormData((f) => ({...f, position: parseInt(e.target.value)}))} label={"Position"} type={"number"}/>
            <InputGroup value={formData?.externalLink} name={"External link"} onChange={(e: ChangeEvent<HTMLInputElement>) => setFormData((f) => ({...f, externalLink: e.target.value}))} label={"External link"} placeholder={"https://mywebsite.com"}/>
            <Divider/>
            <Typography variant="h6">Add sizes</Typography>
            <UploadSizesList items={formData.sizes} name="sizes" label="Add sizes" onChange={(sizes) => setFormData((f) => ({...f, sizes: sizes}))}/>
            <Stack direction={"row"} width={"max-content"} alignItems={"center"} spacing={1} alignSelf={"center"} justifyContent={"space-evenly"}>
                <Typography>Save draft</Typography>
                <Switch name="publish" checked={formData?.published} onChange={(e) => setFormData((f) => ({...f, published: e.target.checked}))}/>
                <Typography>Publish</Typography>
            </Stack>
            <Popup title={"Reorder / remove images"} open={reorderImagesPopup} onClose={() => setReorderImagesPopup(false)}>
                <Form onSubmit={(e) => reorderImages(e as {[key: string]: string})}>
                    <Table>
                        {formData.images?.map((image) =>
                            <TableRow>
                                <TableCell><img width={"30px"} src={image.url}/></TableCell>
                                <TableCell><InputGroup name={`${image?.name.split(".")[0]}`} min={1} type={"number"} label={"Image position"} value={(formData.imageOrder?.indexOf(parseInt(image.name.split(".")[0])) || 0) + 1}/></TableCell>
                                <TableCell><IconButtonPop responsive={false} title="Delete" onClick={async () => await onDeleteImage(parseInt(image.name.split(".")[0]))}><Delete/></IconButtonPop></TableCell>
                            </TableRow>)}
                    </Table>
                </Form>
            </Popup>
        </Form>
    );
}


type UploadSizeParams = {
    items?: {
        [song: string]: MerchSize
    },
    name: string,
    label: string,
    separator?: string,
    required?: boolean,
    onChange?: (e: {
        [song: string]: MerchSize
    }) => void,
}

function UploadSizesList({items, label, separator=", ", required, onChange}:UploadSizeParams) {
    const [fItems, setFItems] = useState<{[song: string]: MerchSize}>({});
    const [error, setError] = useState(false);

    useEffect(() => {
        if (!items) return;

        setFItems(items);
    }, []);

    useEffect(() => {
        if (!onChange || !fItems) return;
        onChange(fItems);
    }, [fItems]);

    const checkError = () => {
        if (!required) return;
        if (!Object.entries(fItems).map(([, v]) => v).filter((v) => v).join(separator).length) {
            setError(true);
            return;
        }
        setError(false);
    };

    return (
        <List sx={{borderBottom: "1px solid #00000050"}}>
            <ListItem sx={{padding: 0, marginBottom: 2}}>
                <ListItemText
                    primary={<Typography color={error ? "error" : "inherit"} variant={"subtitle1"}>{label}&nbsp;{required ? <sup>*</sup> : ""}</Typography>}
                    sx={{paddingRight: "70px"}}
                />
                <ListItemSecondaryAction>
                    <IconButtonPop responsive={false} title="Add size" onClick={() => setFItems((i) => ({...i, [Object.keys(i).length + 1]: {}}))}><AddCircleOutline color="primary"/></IconButtonPop>
                </ListItemSecondaryAction>
            </ListItem>
            {Object.entries(fItems)?.map(([k, v]) =>
                <ListItem key={k} sx={{padding: 0, borderTop: `${PRIMARY_COLOR} 1px solid`, paddingTop: 2, paddingBottom: 2}}>
                    <ListItemText sx={{paddingRight: "70px"}}>
                        <Stack spacing={1}>
                            <Dropdown name="size" label="Size" value={v.name} required onChange={(e) => setFItems((i) => ({...i, [k]: {...i[k], name: e.target.value as SizeNames}}))}>
                                <MenuItem value={"One size"}>One size</MenuItem>
                                <MenuItem value={"XXS"}>XXS</MenuItem>
                                <MenuItem value={"XS"}>XS</MenuItem>
                                <MenuItem value={"S"}>S</MenuItem>
                                <MenuItem value={"M"}>M</MenuItem>
                                <MenuItem value={"L"}>L</MenuItem>
                                <MenuItem value={"XL"}>XL</MenuItem>
                                <MenuItem value={"XXL"}>XXL</MenuItem>
                            </Dropdown>
                            <InputGroup onBlur={checkError} placeholder={"Describe the size, dimensions etc"} value={v.sizeGuide} label={"Size guide"} onChange={(e:ChangeEvent<HTMLInputElement>) => setFItems((i) => ({...i, [k]: {...i[k], sizeGuide: e.target.value}}))}/>
                            <InputGroup onBlur={checkError} type={"number"} min={0} value={v.stock} required label={"Stock"} onChange={(e:ChangeEvent<HTMLInputElement>) => setFItems((i) => ({...i, [k]: {...i[k], stock: parseInt(e.target.value)}}))}/>
                        </Stack>
                    </ListItemText>
                    <ListItemSecondaryAction>
                        <IconButtonPop responsive={false} title="Remove" onClick={() => setFItems((i) => Object.fromEntries(Object.entries(i).filter(([key]) => key !== k)))}><RemoveCircleOutline/></IconButtonPop>
                    </ListItemSecondaryAction>
                </ListItem>
            )}
        </List>
    );
}
