import {useNavigate, useParams} from "react-router-dom";
import Page from "../Components/Page";
import {useEffect, useState} from "react";
import {doc, getDoc} from "firebase/firestore";
import {db, storage} from "../Util/firebase";
import {MusicItem as MusicItemType} from "../Util/typeDefinitions";
import {getDownloadURL, ref} from "firebase/storage";
import {Box, Grid, Stack, Table, TableCell, TableHead, TableRow, Typography, styled} from "@mui/material";
import AddToCart from "../Components/AddToCart";
import IconButtonPop from "../Components/IconButtonPop";
import {ArrowBackRounded} from "@mui/icons-material";
import {formatPrice, useCheckMobileScreen} from "../Util/util";
import AudioPlayer, {useAudioPlayer} from "../Components/AudioSample";

const MusicTableCell = styled(TableCell)`
    padding-left: 0;
    padding-right: 4;
`;

export default function MusicItem() {
    const [item, setItem] = useState<MusicItemType>();
    const {id} = useParams();
    const isMobile = useCheckMobileScreen();

    const navigate = useNavigate();
    const audioPlayer = useAudioPlayer();

    // Get all items
    // For any that have no variants, just add them with their image, stock and price.
    // Individual items, when added to basket just show a summary popup.

    // For those with colors, add them as separate items

    useEffect(() => {
        if (!id) return;
        getDoc(doc(db, `music/${id}`)).then(async (item) => {
            const coverImage = await getDownloadURL(ref(storage, `music/${id}/cover.webp`));
            const album = await getDownloadURL(ref(storage, `music/${id}/album.zip`)).catch(() => undefined);

            const finalData = {...item.data() as MusicItemType, id: `music-${item.id}`, image: coverImage, album: album};

            if (finalData.songs) {
                finalData.songs = Object.fromEntries(await Promise.all(Object.entries(finalData.songs).map(async ([key, song]) => {
                    const image = await getDownloadURL(ref(storage, `music/${id}/${song.position}.webp`)).catch(() => undefined);
                    const songFile = await getDownloadURL(ref(storage, `music/${id}/${song.position}.wav`)).catch(() => undefined);


                    return [key, {...song, image: image, songFile: songFile}];
                })));
            }
            setItem(finalData);
        });
    }, []);

    if (!item) return null;

    console.log("item", item);

    return (
        <Page>
            <Grid container pt={6}>
                <Grid item xs={12} sm={6}>
                    <Box marginBottom={5} m={isMobile ? 1 : 5} mt={0} sx={{backgroundImage: `url(${item.image})`, aspectRatio: "1 / 1", backgroundRepeat: "no-repeat", backgroundSize: "contain", backgroundPosition: "50%", boxShadow: `0px 0px 20px 0px ${item["imageColor" as keyof typeof item]}`}} alignSelf={"center"}/>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography variant={isMobile ? "h4" : "h3"} fontWeight={"bold"} mb={4}><IconButtonPop responsive={false} title="Back" onClick={()=> navigate(-1)}><ArrowBackRounded/></IconButtonPop>{item.name}</Typography>
                    <Typography>{item.description}</Typography>
                    <br/>
                    {item.writtenBy && <Typography>Written by: {item.writtenBy}</Typography>}
                    {item.performedBy && <Typography>Performed by: {item.performedBy}</Typography>}
                    {item.mixAndProd && <Typography>Mix and Production: {item.mixAndProd}</Typography>}
                    {item.coverArt && <Typography>Cover art: {item.coverArt}</Typography>}
                    {item.coverPhoto && <Typography>Cover photo: {item.coverPhoto}</Typography>}
                    {item.coverTaglineArt && <Typography>Cover Tagline art: {item.coverTaglineArt}</Typography>}
                    <br/>
                    <Stack direction={"row"} alignItems={"center"} spacing={3} justifyContent={"end"}>
                        <Typography variant="h6">{formatPrice(item.price)}</Typography>
                        <AddToCart basketButton item={item} disabled={!item["album" as keyof typeof item]}/>
                    </Stack>
                    {item.songs && <Typography mt={5} variant="h6">Songs</Typography>}
                    {item.songs && <Table size="small">
                        <TableHead>
                            <MusicTableCell sx={{paddingRight: 0}}></MusicTableCell>
                            <MusicTableCell sx={{textAlign: "center"}}>Name</MusicTableCell>
                            <MusicTableCell sx={{textAlign: "center"}}>Length</MusicTableCell>
                            <MusicTableCell sx={{textAlign: "center"}}>Price</MusicTableCell>
                            <MusicTableCell sx={{textAlign: "center"}}>Buy</MusicTableCell>
                        </TableHead>
                        {Object.values(item.songs).sort((a, b) => a.position - b.position).map((song) =>
                            <TableRow>
                                <MusicTableCell sx={{paddingRight: 0}}>
                                    {song["songFile" as keyof typeof song] && <AudioPlayer useAudioPlayer={audioPlayer} url={song["songFile" as keyof typeof song] as string}/>}
                                </MusicTableCell>
                                <MusicTableCell sx={{textAlign: "center"}}>{song.name}</MusicTableCell>
                                <MusicTableCell sx={{textAlign: "center"}}>{song.duration}</MusicTableCell>
                                <MusicTableCell sx={{textAlign: "center"}}>{formatPrice(song.price)}</MusicTableCell>
                                <MusicTableCell><AddToCart disabled={!song["songFile" as keyof typeof song]} item={{...item, ...song, id: `${item.id}-${song.position}`, name: `${item.name} - ${song.name}`}}/></MusicTableCell>
                            </TableRow>
                        )}
                    </Table>}
                </Grid>
            </Grid>
        </Page>
    );
}
