import {CardContent, CardHeader, Grid, Card as MuiCard} from "@mui/material";
import {ReactNode} from "react";

type Params = {
    title?: string|ReactNode,
    secondaryTitle?: string|ReactNode,
    children?: ReactNode,
    sx?: {[key:string]: unknown},
    style?: {[key:string]: unknown},
    grid?: boolean,
    xs?: number,
    sm?: number,
    md?: number,
    lg?: number,
    headerSx?: {[key:string]: unknown}
    onClick?: () => void
}

export default function Card({title, secondaryTitle, children, sx, style, grid, xs=12, sm, md, lg, headerSx, onClick}:Params) {
    const cardItem =
    <MuiCard sx={sx} style={style} onClick={onClick}>
        {title && <CardHeader sx={headerSx} title={[title, secondaryTitle]}/>}
        <CardContent sx={{height: "100%", position: "relative"}}>
            {children}
        </CardContent>
    </MuiCard>;

    if (grid) {
        return (
            <Grid item {...{xs, sm, md, lg}}>
                {cardItem}
            </Grid>
        );
    }
    return (cardItem);
}
