import {useNavigate, useParams} from "react-router-dom";
import Page from "../Components/Page";
import {useContext, useEffect, useState} from "react";
import {storage} from "../Util/firebase";
import {getDownloadURL, listAll, ref} from "firebase/storage";
import {Box, Button, Divider, Grid, Stack, Typography, capitalize} from "@mui/material";
import AddToCart from "../Components/AddToCart";
import IconButtonPop from "../Components/IconButtonPop";
import {ArrowBackRounded, Launch} from "@mui/icons-material";
import {PRIMARY_COLOR} from "..";
import {PublicContext} from "../App";
import {formatPrice} from "../Util/util";


export default function MerchItem() {
    const [images, setImages] = useState<string[]>();
    const [selectedImage, setSelectedImage] = useState<string>();
    const {id, size} = useParams();

    const navigate = useNavigate();

    if (!id) return null;

    const item = useContext(PublicContext).merch[id];

    // Get all items
    // For any that have no variants, just add them with their image, stock and price.
    // Individual items, when added to basket just show a summary popup.

    // For those with colors, add them as separate items

    useEffect(() => {
        const getImages = async () => {
            console.log("id", id);
            return await Promise.all((await listAll(ref(storage, `merch/${id}`))).items.map(async (item) => await getDownloadURL(item)));
        };

        getImages().then((images) => {
            setSelectedImage(images[0]);
            setImages(images);
        });
    }, [size]);

    if (!item) return null;

    let selectedSize = size;

    if (Object.values(item.sizes).length === 1) {
        selectedSize = Object.values(item.sizes)[0].name;
    }

    return (
        <Page>
            <Grid container pt={6}>
                <Grid item xs={12} sm={6}>
                    <Box borderBottom={"1px solid rgba(255, 255, 255, 0.5)"} width={"100%"} height={"400px"} sx={{backgroundImage: `url(${selectedImage})`, backgroundRepeat: "no-repeat", backgroundSize: "contain", backgroundPosition: "50%"}} alignSelf={"center"}/>
                    <Stack direction={"row"} mt={2} flexWrap={"wrap"} m={"auto"} width={"max-content"} maxWidth={"100%yarn start"}>
                        {images?.map((image) => <Box m={0.5} onClick={() => setSelectedImage(image)} width={"100px"} height={"100px"} sx={{backgroundImage: `url(${image})`, backgroundRepeat: "no-repeat", backgroundSize: "contain", backgroundPosition: "50%", cursor: "pointer", ...(selectedImage === image ? {border: "black 1px solid", outline: `${PRIMARY_COLOR} 2px solid`} : {})}} alignSelf={"center"}/>)}
                    </Stack>
                </Grid>
                <Grid item xs={12} sm={5}>
                    <Stack spacing={2}>
                        <Typography variant="h3"><IconButtonPop responsive={false} title="Back" onClick={()=> navigate(-1)}><ArrowBackRounded/></IconButtonPop>{item.name}</Typography>
                        {item.sizes && <Stack direction={"row"} spacing={1} flex={1}>
                            {Object.values(item.sizes || {}).sort((sizeA, sizeB) => ["XXS", "XS", "S", "M", "L", "XL", "XXL", "XXXL"].indexOf(sizeA.name) - ["XXS", "XS", "S", "M", "L", "XL", "XXL", "XXXL"].indexOf(sizeB.name))
                                .map((sizeItem) =>
                                    <Box onClick={() => sizeItem.stock > 0 ? navigate(selectedSize ? `/merch/${id}/${sizeItem.name}` : sizeItem.name) : undefined} p={1} alignContent={"center"} textAlign={"center"} height={"30px"} minWidth={sizeItem.name.length > 1 ? undefined : "30px"} border={`1px ${selectedSize === sizeItem.name ? PRIMARY_COLOR : "grey"} solid`} sx={{opacity: sizeItem.stock > 0 ? 1 : 0.5, cursor: "pointer"}}>
                                        <Typography textAlign={"center"} lineHeight={"15px"} color={sizeItem.name === selectedSize ? PRIMARY_COLOR : undefined}>
                                            {sizeItem.name}
                                        </Typography>
                                    </Box>)}
                        </Stack>}
                        <Stack spacing={2} mt={4}>
                            <Divider sx={{pb: 3}}/>
                            <Typography pt={3}>{item.description}</Typography>
                            <Divider sx={{pb: 3}}/>
                            <Typography pt={3} variant="subtitle2">{item.sizing}</Typography>
                            <Stack direction={"row"} alignItems={"center"} justifyContent={item.externalLink ? "end" : "space-between"}>
                                {!item.externalLink && (!selectedSize || item.sizes[selectedSize as string].sizeGuide) && <Box borderBottom={`solid 1px ${PRIMARY_COLOR}`}>{selectedSize ? item.sizes[selectedSize as string].sizeGuide : "Select a size"}</Box>}
                                <Stack direction={"row"} alignItems={"center"} spacing={3} justifyContent={"end"}>
                                    <Typography variant="h6">{formatPrice(item.price)}</Typography>
                                    {item.externalLink ? <Button endIcon={<Launch/>} onClick={() => window.open(item.externalLink, "_blank")}>View item</Button> : (selectedSize && <AddToCart basketButton disabled={!selectedSize} item={{id: `merch-${id}-${selectedSize}`, description: item.description, name: `${item.name} - ${capitalize(selectedSize || "")}`, stock: item.sizes[selectedSize].stock, price: item.price}}/>)}
                                </Stack>
                            </Stack>
                        </Stack>
                    </Stack>
                </Grid>
            </Grid>
        </Page>
    );
}
