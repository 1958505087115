import React from "react";
import loadingIcon from "../Images/loading.svg";
import {keyframes, styled} from "@mui/material";


const preloaderPulsate = keyframes`
  0%   {transform: scale(0.7);}
  100% {transform: scale(0.8);}
`;

const PreloaderImage = styled("img")<{visible?: boolean, size?: number}>`
    width: ${(props) => `${props.size || 40}px`};
    padding-top: 20px;
    margin: auto !important;
    display: ${(props) => props.visible ? "flex" : "none"};
    animation: ${preloaderPulsate} 0.9s alternate infinite ease-in-out;
`;


export default function Preloader({visible, size}:{visible?: boolean, size?: number}) {
    return (
        <PreloaderImage src={loadingIcon as any} size={size} visible={visible} alt="Loading icon" />
    );
}
