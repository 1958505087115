import {Alert, List, ListItem, ListItemSecondaryAction, ListItemText, Stack, Typography} from "@mui/material";
import Card from "../../Components/Card";
import OrderTable from "../../Components/OrderTable";
import Page from "../../Components/Page";
import {useContext, useEffect, useState} from "react";
import {AdminContext} from "./AdminHandler";
import {PRIMARY_COLOR} from "../..";


export default function Admin() {
    const orders = Object.values(useContext(AdminContext).orders || {});

    return (
        <Page title="Admin" grid fullHeight>
            <Card grid xs={12} md={8} title={"Notifications"} sx={{height: "300px"}}>
                <Notifications/>
            </Card>
            <Card grid xs={12} md={4} title={"Order summary"}>
                <List>
                    <ListItem sx={{background: PRIMARY_COLOR, borderRadius: 10, mb: 1}}>
                        <ListItemText primary={"Open orders"} sx={{color: "black"}}/>
                        <ListItemSecondaryAction>
                            <Typography variant="h6" color="black" mr={2}>{orders.filter((order) => order.shipmentOpen).length}</Typography>
                        </ListItemSecondaryAction>
                    </ListItem>
                    <ListItem sx={{background: "#ffffff15", borderRadius: 10, mb: 1}}>
                        <ListItemText primary={"Orders to ship"}/>
                        <ListItemSecondaryAction>
                            <Typography variant="h6" mr={2}>{orders.filter((order) => order.shipmentStatus === "awaitingDispatch").length}</Typography>
                        </ListItemSecondaryAction>
                    </ListItem>
                    <ListItem sx={{background: "#ffffff15", borderRadius: 10, mb: 1}}>
                        <ListItemText primary={"Orders shipping"}/>
                        <ListItemSecondaryAction>
                            <Typography variant="h6" mr={2}>{orders.filter((order) => order.shipmentStatus === "dispatched").length}</Typography>
                        </ListItemSecondaryAction>
                    </ListItem>
                </List>
            </Card>
            <Card grid xs={12} title={"Orders"}>
                <OrderTable/>
            </Card>
        </Page>
    );
}


function Notifications() {
    const [notifs, setNotifs] = useState<{[key: string]: {msg: string, severity: "success"|"warning"|"error"}}>({});

    const data = useContext(AdminContext);

    useEffect(() => {
        const mNotifs: {[key: string]: {msg: string, severity: "success"|"warning"|"error"}} = {};

        Object.values(data.merch || {}).map((item) => {
            Object.values(item.sizes || {}).filter((size) => size.stock < 1).forEach((size) => {
                console.log(`${item.name} | size: ${size.name} is out of stock. Restock and update in the inventory.`);
                mNotifs[`${item.id}-${size.name}`] = {severity: "error", msg: `${item.name} | size: '${size.name}' is out of stock. Restock and update in the inventory.`};
            });
        });

        setNotifs(mNotifs);
    }, [data]);

    return (
        <Stack>
            {Object.values(notifs).length ? Object.values(notifs).map((n) => <Alert severity={n.severity}>{n.msg}</Alert>) : <Typography sx={{background: "#ffffff15", borderRadius: 10}} p={2} textAlign={"center"} >No new notifications</Typography>}
        </Stack>
    );
}
