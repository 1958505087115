import {Card, CardContent, CardHeader, Divider, List, Stack} from "@mui/material";
import {Children, isValidElement, ReactNode, UIEvent, useEffect, useState} from "react";
import Preloader from "./Preloader";
import {SearchBox} from "./SearchBox";
import FilterSelector from "./FilterSelector";

// editCols must be a link to the collection where to alter docs

type Params = {
    title: string|ReactNode,
    data?: {[key:string]: {[key:string]: unknown}},
    children?: ReactNode,
    sx?: {[key:string]: unknown},
    actionButton?: ReactNode,
    onScrollBottom?: () => void,
    loadMoreIcon?: boolean,
    onSearch?: (query:string) => void,
    noResultsText?: ReactNode,
    card?: boolean,
    hideNoResultsText?: boolean,
    filters?: {
        [key: string]: {
            label: string,
            value?: unknown,
            values?: {[key:string|number]: string},
            type?: "dropdown"|string
        }
    },
    urlRef?: string,
}

export default function FilterList({title, data, children, sx, actionButton, onScrollBottom, loadMoreIcon, onSearch, noResultsText="No results", card=true, hideNoResultsText, filters, urlRef}:Params) {
    const [tableData, setTableData] = useState<string[]>([]);
    const [bottom, setBottom] = useState(false);

    const handleScroll = (e:UIEvent<HTMLElement>) => {
        const scrolledToBottom = Math.floor((e.currentTarget.scrollHeight - e.currentTarget.scrollTop)/10) === Math.floor(e.currentTarget.clientHeight/10);

        if (scrolledToBottom && !bottom) {
            setBottom(true);
            onScrollBottom && onScrollBottom();
        } else if (!scrolledToBottom) {
            setBottom(false);
        }
    };

    useEffect(() => {
        if (!data) return;
        setTableData(Object.keys(data));
    }, [data]);

    const search = (query:string) => {
        if (onSearch) {
            onSearch(query);
            return;
        }
        if (!data) return;
        setTableData(() => Object.keys(data).filter((key) => JSON.stringify(data[key]).includes(query)));
    };

    const returnItem = <>
        <List>
            {Children.map(children, (child) => {
                if (!isValidElement(child)) {
                    return child;
                }

                if (!tableData.includes(child.props?.id)) {
                    return null;
                }
                return child;
            })}
        </List>
        {loadMoreIcon ? <Preloader visible/> : Children.count(children) > 0 ? hideNoResultsText ? null : <Divider sx={{marginTop: "10px"}}>End of results</Divider> : <Divider>{noResultsText}</Divider>}
    </>;

    const header = <CardHeader title={[
        title,
        <Stack key={"search"} direction={"row"} alignItems={"center"} spacing={1}>
            {filters && <FilterSelector filters={filters} urlRef={urlRef}/>}
            <SearchBox placeholder={"Search"} onSearch={search}/>
            {actionButton}
        </Stack>]}/>;

    if (card) {
        return (
            <Card sx={{...sx, display: "flex", flexDirection: "column", height: "400px"}}>
                {header}
                <CardContent sx={{overflow: "auto"}} onScroll={handleScroll}>
                    {returnItem}
                </CardContent>
            </Card>
        );
    }
    return (
        <Stack sx={{...sx, height: "400px"}}>
            {header}
            {returnItem}
        </Stack>
    );
}
