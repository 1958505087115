import {useEffect, useState} from "react";
import IconButtonPop from "./IconButtonPop";
import {Pause, PlayArrow} from "@mui/icons-material";

type AudioPlayerParams = {
    playAudio: (url: string) => Promise<void>;
    stopAudio: () => Promise<void>;
    playing: string | false;
}

export function useAudioPlayer() {
    const [playing, setPlaying] = useState<string|false>(false);
    const [audio] = useState(new Audio());


    useEffect(() => {
        return (() => {
            stopAudio();
        });
    }, []);

    const fadeAudio = (direction: "in"|"out", volume?: number):Promise<null> => {
        const fVol = volume || (direction === "in" ? 0 : 1);
        return new Promise((resolve) => setTimeout(async () => {
            audio.volume = fVol;
            const newVolume = direction === "in" ? fVol + 0.05 : fVol - 0.05;

            if (newVolume <= 1 && newVolume >= 0) {
                await fadeAudio(direction, newVolume);
            }
            resolve(null);
        }, 25));
    };

    const stopAudio = async () => {
        setPlaying(false);
        await fadeAudio("out");
        audio.pause();
        audio.currentTime = 0;
    };

    const playAudio = async (url: string) => {
        if (playing) {
            await stopAudio();
        }
        audio.src = url;
        audio.volume = 0;
        setPlaying(url);
        audio.play();
        fadeAudio("in");
        setTimeout(function() {
            stopAudio();
        }, 30000);
    };

    return {playAudio, stopAudio, playing};
}

export default function AudioPlayer({useAudioPlayer, url}:{useAudioPlayer: AudioPlayerParams, url: string}) {
    return (
        <IconButtonPop responsive={false} title={useAudioPlayer.playing === url ? "Pause" : "Play"} onClick={() => useAudioPlayer.playing === url ? useAudioPlayer.stopAudio() : useAudioPlayer.playAudio(url)}>
            {useAudioPlayer.playing === url ? <Pause/> : <PlayArrow/>}
        </IconButtonPop>
    );
}
