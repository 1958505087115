
import {TextField} from "@mui/material";
import Card from "../../Components/Card";
import Form from "../../Components/Form";
import Page from "../../Components/Page";
import {signInWithEmailAndPassword} from "firebase/auth";
import {auth} from "../../Util/firebase";


export default function Login() {
    const login = async (e: {email: string, password: string}) => {
        await signInWithEmailAndPassword(auth, e.email, e.password);
    };

    return (
        <Page sx={{alignItems: "center", justifyContent: "center", paddingTop: "100px"}} fullHeight>
            <Card title={"Sign in"} sx={{width: "500px", maxWidth: "95%", marginTop: "100px"}}>
                <Form onSubmit={(e) => login(e as {email: string, password: string})}>
                    <TextField name="email" label={"Email"} type="email" required/>
                    <TextField name="password" label={"Password"} type="password" required/>
                </Form>
            </Card>
        </Page>
    );
}
