import {Button, Chip, Divider, MenuItem, Table, TableBody, TableCell, TableHead, TableRow, Typography, capitalize} from "@mui/material";
import {useContext, useState} from "react";
import {SuccessTableCell, camelCaseToNormal, countryNames, executeCallable, formatPrice} from "../Util/util";
import IconButtonPop from "./IconButtonPop";
import {ArrowForward} from "@mui/icons-material";
import {AdminContext} from "../Pages/Admin/AdminHandler";
import {Order} from "../Util/typeDefinitions";
import {Popup} from "./Popup";
import Form from "./Form";
import InputGroup from "./FormComponents/InputGroup";
import {PRIMARY_COLOR} from "..";
import Dropdown from "./FormComponents/Dropdown";
import {LoadingButton} from "./LoadingButton";
import {doc, updateDoc} from "firebase/firestore";
import {db} from "../Util/firebase";


export default function OrderTable({open = true}:{open?: boolean}) {
    const [sOrder, setSOrder] = useState<Order&{id: string}>();
    const orders = Object.fromEntries(Object.entries(useContext(AdminContext).orders || {}).filter(([, v]) => v.shipmentOpen === open));

    const markOrderShipped = async (e: {tracking: string, courier: string, id: string}) => {
        await executeCallable("markOrderShipped", e);
    };

    const markOrderDelivered = async (id: string) => {
        return await updateDoc(doc(db, `orders/${id}`), {
            shipmentOpen: false,
            shipmentStatus: "delivered",
        });
    };

    return (
        <>
            <Table>
                <TableHead>
                    <TableCell>Name</TableCell>
                    <TableCell>Total price</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Address</TableCell>
                    <TableCell></TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Actions</TableCell>
                    <TableCell></TableCell>
                </TableHead>
                {orders && Object.entries(orders).map(([orderId, order]) =>
                    <TableRow key={orderId}>
                        <TableCell>{order.name}</TableCell>
                        <TableCell>£{order.totalCost/100}</TableCell>
                        <TableCell>{order.email}</TableCell>
                        <TableCell>{order.addressOne} - {order.postalCode} - {order.country}</TableCell>
                        <TableCell></TableCell>
                        <TableCell><Chip color="primary" variant="outlined" label={camelCaseToNormal(order.shipmentStatus)}/></TableCell>
                        <TableCell align="center">{order.shipmentStatus === "awaitingDispatch" ? <Button variant="contained">Print shipping label</Button> : order.shipmentStatus === "dispatched" ? <LoadingButton noFlexGrow variant="contained" onClick={async () => await markOrderDelivered(orderId)} text="Mark delivered"/> : undefined}</TableCell>
                        <TableCell><IconButtonPop responsive={false} onClick={() => setSOrder({...order, id: orderId})} title="View full order"><ArrowForward/></IconButtonPop></TableCell>
                    </TableRow>
                )}
            </Table>
            <Popup title={"Order: "+sOrder?.id} open={Boolean(sOrder)} onClose={() => setSOrder(undefined)}>
                <Typography variant="h5">Customer details</Typography>
                <Table>
                    <TableRow>
                        <TableCell>Customer name</TableCell>
                        <TableCell>{sOrder?.name}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Email</TableCell>
                        <TableCell>{sOrder?.email}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Address</TableCell>
                        <TableCell>{sOrder?.addressOne}, {sOrder?.addressTwo}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell>{sOrder?.city}, {sOrder?.state}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell>{sOrder?.postalCode}, {countryNames[sOrder?.country as string]}</TableCell>
                    </TableRow>
                </Table>
                <Divider sx={{borderTop: `2px solid ${PRIMARY_COLOR}`}}/>
                <Typography variant="h5">Item - details</Typography>
                <Table>
                    <TableHead>
                        <SuccessTableCell sx={{fontWeight: "bold"}}>Item</SuccessTableCell>
                        <SuccessTableCell sx={{fontWeight: "bold"}}>Quantity</SuccessTableCell>
                        <SuccessTableCell sx={{fontWeight: "bold"}}>Unit price</SuccessTableCell>
                        <SuccessTableCell sx={{fontWeight: "bold"}}>Total price</SuccessTableCell>
                        <SuccessTableCell sx={{fontWeight: "bold"}}>Status</SuccessTableCell>
                    </TableHead>
                    <TableBody>
                        {sOrder?.items && Object.entries(sOrder?.items).map(([key, item]) =>
                            <TableRow key={key}>
                                <SuccessTableCell>{item.itemDesc}</SuccessTableCell>
                                <SuccessTableCell>{item.quantity}</SuccessTableCell>
                                <SuccessTableCell>{formatPrice(item.unitPrice)}</SuccessTableCell>
                                <SuccessTableCell>{formatPrice(item.quantity*item.unitPrice)}</SuccessTableCell>
                                <SuccessTableCell>{item.type === "music" ? "Complete" : <Typography>{capitalize(camelCaseToNormal(sOrder.shipmentStatus))}</Typography>}</SuccessTableCell>
                            </TableRow>)}
                    </TableBody>
                </Table>
                {sOrder?.shipmentStatus === "awaitingDispatch" && <Form onSubmit={(e) => markOrderShipped(e as {tracking: string, courier: string, id: string})} sx={{borderTop: `2px solid ${PRIMARY_COLOR}`}}>
                    <Typography variant="h6">Mark as shipped</Typography>
                    <input value={sOrder.id} name={"id"} hidden/>
                    <InputGroup label={"Tracking ID"} name={"tracking"} required/>
                    <Dropdown name={"courier"} required label={"Courier"}>
                        <MenuItem value={"Royal Mail"}>Royal Mail</MenuItem>
                    </Dropdown>
                </Form>}
                {sOrder?.shipmentStatus === "dispatched" && <LoadingButton onClick={async () => markOrderDelivered(sOrder.id)} text="Mark order delivered"/>}
            </Popup>
        </>
    );
}
