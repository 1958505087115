import {Box, Chip, Icon, List, ListItemButton, ListItemSecondaryAction, MenuItem, Popover, Stack, Typography} from "@mui/material";
import IconButtonPop from "./IconButtonPop";
import {ArrowForward, Check, Close, Tune} from "@mui/icons-material";
import {ChangeEvent, RefObject, createRef, useEffect, useState} from "react";
import Dropdown from "./FormComponents/Dropdown";
import InputGroup from "./FormComponents/InputGroup";
import {useNavigate, useSearchParams} from "react-router-dom";


type Params = {
    filters: {
        [key: string]: {
            label: string,
            value?: unknown,
            values?: {[key:string|number]: string},
            type?: "dropdown"|string
        }
    },
    urlRef?: string
}

export default function FilterSelector({filters, urlRef}: Params) {
    const [filtersOpen, setFiltersOpen] = useState<Element>();
    const [selectedFilters, setSelectedFilters] = useState<{[key: string]: {label: string, open: boolean, value?: unknown, type?: string, values?: {[key: string|number]: string}, ref: RefObject<HTMLDivElement>}}>();
    const [filterOpen, setFilterOpen] = useState<string>();
    const [filterRefs, setFilterRefs] = useState<{[key: string]: RefObject<HTMLDivElement>}>();

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    useEffect(() => {
        const fFilters = Object.fromEntries([...searchParams]);
        const filterElementId = fFilters.id;
        delete fFilters.id;

        const providedFilters = Object.fromEntries(Object.entries(filters || {}).map((([name, item]) => {
            const value = item.value || filterElementId === urlRef ? fFilters[name] : undefined;
            if (!value) return [];

            return [name, {
                label: item.label,
                open: false,
                value: value,
                type: item.type,
                values: item.values,
                ref: createRef(),
            }];
        })).filter((x) => x.length));

        if (!providedFilters) return;
        setSelectedFilters(providedFilters);
    }, [filters]);

    useEffect(() => {
        if (!selectedFilters) return;
        setFilterRefs(() => {
            // all current refs
            const finalRefs:{[key:string]: RefObject<HTMLDivElement>} = {};
            Object.entries(selectedFilters).forEach(([k, v]) => {
                finalRefs[k] = v.ref;
            });

            return finalRefs;
        });
    }, [selectedFilters]);

    const handleFilterButtonClick = (event:React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (event.detail === 0) return;
        setFiltersOpen(event.currentTarget);
    };

    const submitFilters = (e: {[name: string]: {value?: unknown}}) => {
        // onFilterChange && onFilterChange(Object.fromEntries(Object.entries(e || {}).map(([k, v]) => [k, v.value])));

        const query = Object.entries(e).reduce((acc, [name, item]) => {
            acc.push(`${name}=${item.value}`);
            return acc;
        }, [] as Array<string>);

        urlRef && query.unshift(`id=${urlRef}`);

        navigate(`?${query.join("&")}`);
        setFilterOpen(undefined);
    };

    return (
        <Stack direction={"row"} alignItems={"center"}>
            {selectedFilters && Object.entries(selectedFilters).map(([key, filter]) =>
                <>
                    <Chip ref={filter.ref} key={key} onClick={() => setFilterOpen(key)} label={filter.label} variant={filterOpen === key ? "filled" : "outlined"} color="primary" aria-describedby={"filterPopup"+key}/>
                    <Popover
                        open={Boolean(filterOpen === key && filterRefs?.[key]?.current)}
                        onClose={() => setFilterOpen(undefined)}
                        id={"filterPopup"+key}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "center",
                        }}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                        }}
                        sx={{marginTop: 1}}
                        anchorEl={filter.ref.current}>
                        <Stack direction={"row"} spacing={0} p={1}>
                            {filter.type === "dropdown" ?
                                <Dropdown label={"Filter"} sx={{minWidth: "150px"}} value={selectedFilters[key].value as string} onChange={(e) => setSelectedFilters((s) => ({...s, [key]: {...(s || {})[key], value: e.target.value}}))}>
                                    {filter.values && Object.entries(filter.values).map(([name, label]) => <MenuItem value={name}>{label}</MenuItem>)}
                                </Dropdown> :
                                <InputGroup placeholder={"Filter"} value={selectedFilters[key].value} onChange={(e: ChangeEvent<HTMLInputElement>) => setSelectedFilters((s) => ({...s, [key]: {...(s || {})[key], value: e.target.value}}))}/>}
                            <IconButtonPop responsive={false} title="Go" onClick={() => submitFilters(selectedFilters)}><Check/></IconButtonPop>
                            <IconButtonPop responsive={false} title="Remove" onClick={() => {
                                submitFilters(Object.fromEntries(Object.entries(selectedFilters || {}).filter(([k]) => k !== key)));
                            }}><Close/></IconButtonPop>
                        </Stack>
                    </Popover>
                </>)}
            {filters && <IconButtonPop responsive={false} aria-describedby={"filterPopup"} title={`Filters ${Object.keys(selectedFilters || {}).length ? `(${Object.keys(selectedFilters || {}).length})` : ""}`} onClick={(e) => handleFilterButtonClick(e)}><Tune/></IconButtonPop>}
            {filters && <Popover
                open={Boolean(filtersOpen)}
                onClose={() => setFiltersOpen(undefined)}
                id={"filterPopup"}
                anchorEl={filtersOpen}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
            >
                <Box sx={{minWidth: "200px"}}>
                    <Typography p={2} pb={0}>Filter items</Typography>
                    <List>
                        {Object.entries(filters).map(([filterName, obj]) =>
                            <ListItemButton onClick={() => {
                                setSelectedFilters((f) => ({...f, [filterName]: {label: obj.label, open: true, type: obj.type, values: obj.values, ref: createRef()}}));
                                setFilterOpen(filterName);
                                setFiltersOpen(undefined);
                            }}>
                                <Chip label={obj.label} color={(selectedFilters && selectedFilters[filterName]) && "primary"}/>
                                <ListItemSecondaryAction>
                                    {selectedFilters && selectedFilters[filterName] ?
                                        <IconButtonPop responsive={false} title="Remove filter" onClick={(e) => {
                                            setFiltersOpen(undefined);
                                            submitFilters(Object.fromEntries(Object.entries(selectedFilters || {}).filter(([k]) => k !== filterName)));
                                            e.stopPropagation();
                                        }}>
                                            <Close/>
                                        </IconButtonPop> :
                                        <Icon sx={{verticalAlign: "middle", opacity: 0.5}}>
                                            <ArrowForward/>
                                        </Icon>}
                                </ListItemSecondaryAction>
                            </ListItemButton>
                        )}
                    </List>
                </Box>
            </Popover>}
        </Stack>
    );
}
