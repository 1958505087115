/* eslint-disable camelcase */
import Page from "../Components/Page";
import {Box, Grid, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography} from "@mui/material";
import {useShoppingCart} from "use-shopping-cart";
import {executeCallable, formatPrice, hasMerch} from "../Util/util";
import {LoadingButton} from "../Components/LoadingButton";
import AddToCart, {CartMerchItem} from "../Components/AddToCart";
import {Appearance, StripeAddressElementOptions, loadStripe} from "@stripe/stripe-js";
import {AddressElement, Elements, PaymentElement, useStripe, useElements} from "@stripe/react-stripe-js";
import {useRef, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import Card from "../Components/Card";
import Form from "../Components/Form";
import InputGroup from "../Components/FormComponents/InputGroup";
import {PRIMARY_COLOR} from "..";


const countries: {[key: string]: "UK mainland"|"European"|"Worldwide"} = {
    "AF": "Worldwide",
    "AX": "European",
    "AL": "European",
    "DZ": "Worldwide",
    "AD": "European",
    "AO": "Worldwide",
    "AI": "Worldwide",
    "AQ": "Worldwide",
    "AG": "Worldwide",
    "AR": "Worldwide",
    "AM": "Worldwide",
    "AW": "Worldwide",
    "AC": "Worldwide",
    "AU": "Worldwide",
    "AT": "European",
    "AZ": "Worldwide",
    "BS": "Worldwide",
    "BH": "Worldwide",
    "BD": "Worldwide",
    "BB": "Worldwide",
    "BY": "European",
    "BE": "European",
    "BZ": "Worldwide",
    "BJ": "Worldwide",
    "BM": "Worldwide",
    "BT": "Worldwide",
    "BO": "Worldwide",
    "BA": "European",
    "BW": "Worldwide",
    "BV": "Worldwide",
    "BR": "Worldwide",
    "IO": "Worldwide",
    "VG": "Worldwide",
    "BN": "Worldwide",
    "BG": "European",
    "BF": "Worldwide",
    "BI": "Worldwide",
    "KH": "Worldwide",
    "CM": "Worldwide",
    "CA": "Worldwide",
    "CV": "Worldwide",
    "BQ": "Worldwide",
    "KY": "Worldwide",
    "CF": "Worldwide",
    "TD": "Worldwide",
    "CL": "Worldwide",
    "CN": "Worldwide",
    "CO": "Worldwide",
    "KM": "Worldwide",
    "CG": "Worldwide",
    "CD": "Worldwide",
    "CK": "Worldwide",
    "CR": "Worldwide",
    "CI": "Worldwide",
    "HR": "European",
    "CW": "Worldwide",
    "CY": "European",
    "CZ": "European",
    "DK": "European",
    "DJ": "Worldwide",
    "DM": "Worldwide",
    "DO": "Worldwide",
    "EC": "Worldwide",
    "EG": "Worldwide",
    "SV": "Worldwide",
    "GQ": "Worldwide",
    "ER": "Worldwide",
    "EE": "European",
    "SZ": "Worldwide",
    "ET": "Worldwide",
    "FK": "Worldwide",
    "FO": "Worldwide",
    "FJ": "Worldwide",
    "FI": "European",
    "FR": "European",
    "GF": "Worldwide",
    "PF": "Worldwide",
    "TF": "Worldwide",
    "GA": "Worldwide",
    "GM": "Worldwide",
    "GE": "Worldwide",
    "DE": "European",
    "GH": "Worldwide",
    "GI": "European",
    "GR": "European",
    "GL": "Worldwide",
    "GD": "Worldwide",
    "GP": "Worldwide",
    "GU": "Worldwide",
    "GT": "Worldwide",
    "GG": "Worldwide",
    "GN": "Worldwide",
    "GW": "Worldwide",
    "GY": "Worldwide",
    "HT": "Worldwide",
    "HN": "Worldwide",
    "HK": "Worldwide",
    "HU": "European",
    "IS": "European",
    "IN": "Worldwide",
    "ID": "Worldwide",
    "IQ": "Worldwide",
    "IE": "European",
    "IM": "Worldwide",
    "IL": "Worldwide",
    "IT": "European",
    "JM": "Worldwide",
    "JP": "Worldwide",
    "JE": "Worldwide",
    "JO": "Worldwide",
    "KZ": "Worldwide",
    "KE": "Worldwide",
    "KI": "Worldwide",
    "XK": "European",
    "KW": "Worldwide",
    "KG": "Worldwide",
    "LA": "Worldwide",
    "LV": "European",
    "LB": "Worldwide",
    "LS": "Worldwide",
    "LR": "Worldwide",
    "LY": "Worldwide",
    "LI": "European",
    "LT": "European",
    "LU": "European",
    "MO": "Worldwide",
    "MG": "Worldwide",
    "MW": "Worldwide",
    "MY": "Worldwide",
    "MV": "Worldwide",
    "ML": "Worldwide",
    "MT": "European",
    "MQ": "Worldwide",
    "MR": "Worldwide",
    "MU": "Worldwide",
    "YT": "Worldwide",
    "MX": "Worldwide",
    "MD": "European",
    "MC": "European",
    "MN": "Worldwide",
    "ME": "European",
    "MS": "Worldwide",
    "MA": "Worldwide",
    "MZ": "Worldwide",
    "MM": "Worldwide",
    "NA": "Worldwide",
    "NR": "Worldwide",
    "NP": "Worldwide",
    "NL": "European",
    "NC": "Worldwide",
    "NZ": "Worldwide",
    "NI": "Worldwide",
    "NE": "Worldwide",
    "NG": "Worldwide",
    "NU": "Worldwide",
    "MK": "European",
    "NO": "European",
    "OM": "Worldwide",
    "PK": "Worldwide",
    "PS": "Worldwide",
    "PA": "Worldwide",
    "PG": "Worldwide",
    "PY": "Worldwide",
    "PE": "Worldwide",
    "PH": "Worldwide",
    "PN": "Worldwide",
    "PL": "European",
    "PT": "European",
    "PR": "Worldwide",
    "QA": "Worldwide",
    "RE": "Worldwide",
    "RO": "European",
    "RU": "European",
    "RW": "Worldwide",
    "WS": "Worldwide",
    "SM": "European",
    "ST": "Worldwide",
    "SA": "Worldwide",
    "SN": "Worldwide",
    "RS": "European",
    "SC": "Worldwide",
    "SL": "Worldwide",
    "SG": "Worldwide",
    "SX": "Worldwide",
    "SK": "European",
    "SI": "European",
    "SB": "Worldwide",
    "SO": "Worldwide",
    "ZA": "Worldwide",
    "GS": "Worldwide",
    "KR": "Worldwide",
    "SS": "Worldwide",
    "ES": "European",
    "LK": "Worldwide",
    "BL": "Worldwide",
    "SH": "Worldwide",
    "KN": "Worldwide",
    "LC": "Worldwide",
    "MF": "Worldwide",
    "PM": "Worldwide",
    "VC": "Worldwide",
    "SD": "Worldwide",
    "SR": "Worldwide",
    "SJ": "Worldwide",
    "SE": "European",
    "CH": "European",
    "TW": "Worldwide",
    "TJ": "Worldwide",
    "TZ": "Worldwide",
    "TH": "Worldwide",
    "TL": "Worldwide",
    "TG": "Worldwide",
    "TK": "Worldwide",
    "TO": "Worldwide",
    "TT": "Worldwide",
    "TA": "Worldwide",
    "TN": "Worldwide",
    "TR": "European",
    "TM": "Worldwide",
    "TC": "Worldwide",
    "TV": "Worldwide",
    "UG": "Worldwide",
    "UA": "European",
    "AE": "Worldwide",
    "GB": "UK mainland",
    "US": "Worldwide",
    "UY": "Worldwide",
    "UZ": "Worldwide",
    "VU": "Worldwide",
    "VA": "European",
    "VE": "Worldwide",
    "VN": "Worldwide",
    "WF": "Worldwide",
    "EH": "Worldwide",
    "YE": "Worldwide",
    "ZM": "Worldwide",
    "ZW": "Worldwide",
};

const countryNames: {[key: string]: string} = {
    "AF": "Afghanistan",
    "AX": "Åland Islands",
    "AL": "Albania",
    "DZ": "Algeria",
    "AD": "Andorra",
    "AO": "Angola",
    "AI": "Anguilla",
    "AQ": "Antarctica",
    "AG": "Antigua & Barbuda",
    "AR": "Argentina",
    "AM": "Armenia",
    "AW": "Aruba",
    "AC": "Ascension Island",
    "AU": "Australia",
    "AT": "Austria",
    "AZ": "Azerbaijan",
    "BS": "Bahamas",
    "BH": "Bahrain",
    "BD": "Bangladesh",
    "BB": "Barbados",
    "BY": "Belarus",
    "BE": "Belgium",
    "BZ": "Belize",
    "BJ": "Benin",
    "BM": "Bermuda",
    "BT": "Bhutan",
    "BO": "Bolivia",
    "BA": "Bosnia & Herzegovina",
    "BW": "Botswana",
    "BV": "Bouvet Island",
    "BR": "Brazil",
    "IO": "British Indian Ocean Territory",
    "VG": "British Virgin Islands",
    "BN": "Brunei",
    "BG": "Bulgaria",
    "BF": "Burkina Faso",
    "BI": "Burundi",
    "KH": "Cambodia",
    "CM": "Cameroon",
    "CA": "Canada",
    "CV": "Cape Verde",
    "BQ": "Caribbean Netherlands",
    "KY": "Cayman Islands",
    "CF": "Central African Republic",
    "TD": "Chad",
    "CL": "Chile",
    "CN": "China",
    "CO": "Colombia",
    "KM": "Comoros",
    "CG": "Congo - Brazzaville",
    "CD": "Congo - Kinshasa",
    "CK": "Cook Islands",
    "CR": "Costa Rica",
    "CI": "Côte d’Ivoire",
    "HR": "Croatia",
    "CW": "Curaçao",
    "CY": "Cyprus",
    "CZ": "Czechia",
    "DK": "Denmark",
    "DJ": "Djibouti",
    "DM": "Dominica",
    "DO": "Dominican Republic",
    "EC": "Ecuador",
    "EG": "Egypt",
    "SV": "El Salvador",
    "GQ": "Equatorial Guinea",
    "ER": "Eritrea",
    "EE": "Estonia",
    "SZ": "Eswatini",
    "ET": "Ethiopia",
    "FK": "Falkland Islands",
    "FO": "Faroe Islands",
    "FJ": "Fiji",
    "FI": "Finland",
    "FR": "France",
    "GF": "French Guiana",
    "PF": "French Polynesia",
    "TF": "French Southern Territories",
    "GA": "Gabon",
    "GM": "Gambia",
    "GE": "Georgia",
    "DE": "Germany",
    "GH": "Ghana",
    "GI": "Gibraltar",
    "GR": "Greece",
    "GL": "Greenland",
    "GD": "Grenada",
    "GP": "Guadeloupe",
    "GU": "Guam",
    "GT": "Guatemala",
    "GG": "Guernsey",
    "GN": "Guinea",
    "GW": "Guinea-Bissau",
    "GY": "Guyana",
    "HT": "Haiti",
    "HN": "Honduras",
    "HK": "Hong Kong SAR China",
    "HU": "Hungary",
    "IS": "Iceland",
    "IN": "India",
    "ID": "Indonesia",
    "IQ": "Iraq",
    "IE": "Ireland",
    "IM": "Isle of Man",
    "IL": "Israel",
    "IT": "Italy",
    "JM": "Jamaica",
    "JP": "Japan",
    "JE": "Jersey",
    "JO": "Jordan",
    "KZ": "Kazakhstan",
    "KE": "Kenya",
    "KI": "Kiribati",
    "XK": "Kosovo",
    "KW": "Kuwait",
    "KG": "Kyrgyzstan",
    "LA": "Laos",
    "LV": "Latvia",
    "LB": "Lebanon",
    "LS": "Lesotho",
    "LR": "Liberia",
    "LY": "Libya",
    "LI": "Liechtenstein",
    "LT": "Lithuania",
    "LU": "Luxembourg",
    "MO": "Macao SAR China",
    "MG": "Madagascar",
    "MW": "Malawi",
    "MY": "Malaysia",
    "MV": "Maldives",
    "ML": "Mali",
    "MT": "Malta",
    "MQ": "Martinique",
    "MR": "Mauritania",
    "MU": "Mauritius",
    "YT": "Mayotte",
    "MX": "Mexico",
    "MD": "Moldova",
    "MC": "Monaco",
    "MN": "Mongolia",
    "ME": "Montenegro",
    "MS": "Montserrat",
    "MA": "Morocco",
    "MZ": "Mozambique",
    "MM": "Myanmar (Burma)",
    "NA": "Namibia",
    "NR": "Nauru",
    "NP": "Nepal",
    "NL": "Netherlands",
    "NC": "New Caledonia",
    "NZ": "New Zealand",
    "NI": "Nicaragua",
    "NE": "Niger",
    "NG": "Nigeria",
    "NU": "Niue",
    "MK": "North Macedonia",
    "NO": "Norway",
    "OM": "Oman",
    "PK": "Pakistan",
    "PS": "Palestinian Territories",
    "PA": "Panama",
    "PG": "Papua New Guinea",
    "PY": "Paraguay",
    "PE": "Peru",
    "PH": "Philippines",
    "PN": "Pitcairn Islands",
    "PL": "Poland",
    "PT": "Portugal",
    "PR": "Puerto Rico",
    "QA": "Qatar",
    "RE": "Réunion",
    "RO": "Romania",
    "RU": "Russia",
    "RW": "Rwanda",
    "WS": "Samoa",
    "SM": "San Marino",
    "ST": "São Tomé & Príncipe",
    "SA": "Saudi Arabia",
    "SN": "Senegal",
    "RS": "Serbia",
    "SC": "Seychelles",
    "SL": "Sierra Leone",
    "SG": "Singapore",
    "SX": "Sint Maarten",
    "SK": "Slovakia",
    "SI": "Slovenia",
    "SB": "Solomon Islands",
    "SO": "Somalia",
    "ZA": "South Africa",
    "GS": "South Georgia & South Sandwich Islands",
    "KR": "South Korea",
    "SS": "South Sudan",
    "ES": "Spain",
    "LK": "Sri Lanka",
    "BL": "St. Barthélemy",
    "SH": "St. Helena",
    "KN": "St. Kitts & Nevis",
    "LC": "St. Lucia",
    "MF": "St. Martin",
    "PM": "St. Pierre & Miquelon",
    "VC": "St. Vincent & Grenadines",
    "SD": "Sudan",
    "SR": "Suriname",
    "SJ": "Svalbard & Jan Mayen",
    "SE": "Sweden",
    "CH": "Switzerland",
    "TW": "Taiwan",
    "TJ": "Tajikistan",
    "TZ": "Tanzania",
    "TH": "Thailand",
    "TL": "Timor-Leste",
    "TG": "Togo",
    "TK": "Tokelau",
    "TO": "Tonga",
    "TT": "Trinidad & Tobago",
    "TA": "Tristan da Cunha",
    "TN": "Tunisia",
    "TR": "Türkiye",
    "TM": "Turkmenistan",
    "TC": "Turks & Caicos Islands",
    "TV": "Tuvalu",
    "UG": "Uganda",
    "UA": "Ukraine",
    "AE": "United Arab Emirates",
    "GB": "United Kingdom",
    "US": "United States",
    "UY": "Uruguay",
    "UZ": "Uzbekistan",
    "VU": "Vanuatu",
    "VA": "Vatican City",
    "VE": "Venezuela",
    "VN": "Vietnam",
    "WF": "Wallis & Futuna",
    "EH": "Western Sahara",
    "YE": "Yemen",
    "ZM": "Zambia",
    "ZW": "Zimbabwe",
};

export default function ViewBasket() {
    const {/* redirectToCheckout, */ cartCount, cartDetails, formattedTotalPrice} = useShoppingCart();
    const navigate = useNavigate();

    return (
        <Page
            metaTitle="OMA | Basket" title="BASKET" back>
            <Table>
                <TableHead>
                    <TableCell sx={{fontWeight: "bold"}}>Item</TableCell>
                    <TableCell sx={{fontWeight: "bold"}}>Quantity</TableCell>
                    <TableCell sx={{fontWeight: "bold"}}>Unit price</TableCell>
                    <TableCell sx={{fontWeight: "bold"}}>Total price</TableCell>
                    <TableCell sx={{fontWeight: "bold"}}>Edit item</TableCell>
                </TableHead>
                <TableBody>
                    {cartDetails && Object.entries(cartDetails).map(([key, item]) =>
                        <TableRow key={key}>
                            <TableCell>{item.name}</TableCell>
                            <TableCell>{item.quantity}</TableCell>
                            <TableCell>{formatPrice(item.price)}</TableCell>
                            <TableCell>{formatPrice(item.price * item.quantity)}</TableCell>
                            <TableCell><AddToCart alignLeft item={item as CartMerchItem}/></TableCell>
                        </TableRow>)}
                </TableBody>
            </Table>
            <Stack pt={2} spacing={2} alignItems={"center"}>
                <Typography variant="h6">Total: {formattedTotalPrice}</Typography>
                {cartCount !== undefined && Boolean(cartCount) && <Box>
                    {cartCount > 40 ? <Typography borderRadius={2} p={1} border={`1px solid ${PRIMARY_COLOR}`}>Cannot order more than 40 items.</Typography> :
                        <LoadingButton width={"100px"} onClick={async () => navigate("/checkout")} sx={{fontSize: "1.25em", width: "max-content"}} text="Pay now"/>}
                </Box>}
            </Stack>
        </Page>
    );
}


type CheckoutObject = {
    name: string;
    email: string;
    line1: string;
    line2: string | null;
    city: string;
    state: string;
    postal_code: string;
    country: string;
}


export function CheckoutHandler() {
    const {/* redirectToCheckout, cartCount,*/ cartDetails, formattedTotalPrice} = useShoppingCart();

    const [details, setDetails] = useState<CheckoutObject>();

    const paymentRef = useRef<HTMLDivElement>(null);
    const navigate = useNavigate();

    console.log("HASMERCH", hasMerch(cartDetails));

    const submitAddress = async (e: CheckoutObject) => {
        const currentDetails = {...details, ...e};

        if (!currentDetails.name ||
            !currentDetails.email ||
            !currentDetails.line1 ||
            (!currentDetails.city && !currentDetails.state)||
            !currentDetails.postal_code ||
            !currentDetails.country
        ) {
            throw new Error("Please fill out all fields.");
        }

        const secret = await executeCallable("createCheckoutSession", {items: cartDetails, shipping: countries[currentDetails.country], redirect: "https://omaband.com/success"}) as {data: {secret: string, session: string}};
        console.log("DETIALS", secret.data);

        navigate("/payment", {state: {details: currentDetails, clientSecret: secret.data.secret, sessionId: secret.data.session, shipping: countries[currentDetails.country]}});
    };


    const stripe = loadStripe("pk_live_51OT4MdEO4uVGvLqo7CbJQBwe8mjMBiEAqmNBWy3haWD33bnt6yyMVuPiucitHzrjiblAky1VZEqMiPXzzhQJDEDE00nCFZooNO");


    const appearance = {
        theme: "night",

        variables: {
            colorPrimary: PRIMARY_COLOR,
            colorBackground: "#222222",
            colorText: "#ffffff",
            colorDanger: "#df1b41",
            borderRadius: "4px",
            // See all possible variables below
        },
    } as Appearance;


    return (
        <Page back={"/basket"} title="CHECKOUT">
            <Elements stripe={stripe} options={{appearance: appearance}}>
                <Grid container>
                    <Grid item xs={12} md={6}>
                        <Stack flex={1} spacing={2}>
                            <Card>
                                <Table>
                                    <div ref={paymentRef}/>
                                    <TableHead>
                                        <TableCell sx={{fontWeight: "bold"}}>Item</TableCell>
                                        <TableCell sx={{fontWeight: "bold"}}>Quantity</TableCell>
                                        <TableCell sx={{fontWeight: "bold"}}>Unit price</TableCell>
                                        <TableCell sx={{fontWeight: "bold"}}>Total price</TableCell>
                                    </TableHead>
                                    <TableBody>
                                        {cartDetails && Object.entries(cartDetails).map(([key, item]) =>
                                            <TableRow key={key}>
                                                <TableCell>{item.name}</TableCell>
                                                <TableCell>{item.quantity}</TableCell>
                                                <TableCell>{formatPrice(item.price)}</TableCell>
                                                <TableCell>{formatPrice(item.price * item.quantity)}</TableCell>
                                            </TableRow>)}
                                        <TableRow><TableCell colSpan={4}>&nbsp;</TableCell></TableRow>
                                        <TableRow>
                                            <TableCell sx={{fontWeight: "bold"}}>Shipping cost</TableCell>
                                            <TableCell sx={{textAlign: "end"}} colSpan={3}>{hasMerch(cartDetails) ? "Enter your address" : "Digital item, no shipping."}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                                <Typography variant="h6" mt={2} textAlign={"end"}>Total: {formattedTotalPrice}</Typography>
                            </Card>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Stack flex={1} spacing={2}>
                            <Card title={"Contact details"}>
                                <Form sx={{maxWidth: "650px", margin: "auto"}} submitText="Next" onSubmit={async (e) => await submitAddress(e as CheckoutObject)}>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <InputGroup name={"email"} label={"Email address"} required/>
                                        </Grid>
                                    </Grid>
                                    <AddressElement onChange={(e) => setDetails((d) => ({...d, ...e.value.address as CheckoutObject, name: e.value.name}))} options={{mode: "shipping", theme: "night"} as StripeAddressElementOptions} />
                                </Form>
                            </Card>
                        </Stack>
                    </Grid>
                </Grid>
            </Elements>
        </Page>

    );
}


export function PaymentHandler() {
    const {/* redirectToCheckout, cartCount,*/ cartDetails, totalPrice} = useShoppingCart();

    const stripe = loadStripe("pk_live_51OT4MdEO4uVGvLqo7CbJQBwe8mjMBiEAqmNBWy3haWD33bnt6yyMVuPiucitHzrjiblAky1VZEqMiPXzzhQJDEDE00nCFZooNO");

    const {state} = useLocation();
    const {details, clientSecret, shipping, sessionId}:{details: CheckoutObject, clientSecret: string, sessionId: string, shipping: "UK mainland"|"European"|"Worldwide"} = state;

    const appearance = {
        theme: "night",

        variables: {
            colorPrimary: PRIMARY_COLOR,
            colorBackground: "#222222",
            colorText: "#ffffff",
            colorDanger: "#df1b41",
            borderRadius: "4px",
            // See all possible variables below
        },
    } as Appearance;


    return (
        <Page back={"/checkout"} title="PAYMENT">
            <Elements stripe={stripe} options={{appearance: appearance, clientSecret: clientSecret}}>
                <Grid container>
                    <Grid item xs={12} md={6}>
                        <Stack flex={1} spacing={2}>
                            <Card title={"Contact details"}>
                                <Table>
                                    <TableRow>
                                        <TableCell>{details.name}</TableCell>
                                        <TableCell>{details.email}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>{details.line1}</TableCell>
                                        <TableCell>{details.line2}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>{details.postal_code}</TableCell>
                                        <TableCell>{countryNames[details.country]}</TableCell>
                                    </TableRow>
                                </Table>
                            </Card>
                            <Card title={"Order details"}>
                                <Table>
                                    <TableHead>
                                        <TableCell sx={{fontWeight: "bold"}}>Item</TableCell>
                                        <TableCell sx={{fontWeight: "bold"}}>Quantity</TableCell>
                                        <TableCell sx={{fontWeight: "bold"}}>Unit price</TableCell>
                                        <TableCell sx={{fontWeight: "bold"}}>Total price</TableCell>
                                    </TableHead>
                                    <TableBody>
                                        {cartDetails && Object.entries(cartDetails).map(([key, item]) =>
                                            <TableRow key={key}>
                                                <TableCell>{item.name}</TableCell>
                                                <TableCell>{item.quantity}</TableCell>
                                                <TableCell>{formatPrice(item.price)}</TableCell>
                                                <TableCell>{formatPrice(item.price * item.quantity)}</TableCell>
                                            </TableRow>)}
                                        <TableRow><TableCell colSpan={4}>&nbsp;</TableCell></TableRow>
                                        <TableRow>
                                            <TableCell sx={{fontWeight: "bold"}}>Shipping cost</TableCell>
                                            {hasMerch(cartDetails) ? <>
                                                <TableCell sx={{fontWeight: "bold"}} colSpan={2}>{shipping}</TableCell>
                                                <TableCell sx={{fontWeight: "bold"}}>{shipping ? shipping === "UK mainland" ? "Free" : formatPrice(shipping === "European" ? 499 : 1499) : "Input address"}</TableCell>
                                            </> : <TableCell sx={{textAlign: "end"}} colSpan={3}>Digital item, no shipping.</TableCell>}
                                        </TableRow>
                                    </TableBody>
                                </Table>
                                <Typography variant="h6" mt={2} textAlign={"end"}>Total: {formatPrice((totalPrice || 0) + (hasMerch(cartDetails) ? (shipping === "UK mainland" ? 0 : shipping === "European" ? 499 : 1499) : 0))}</Typography>
                            </Card>
                        </Stack>
                    </Grid>
                    <Card grid xs={12} md={6} title={"Payment details"}>
                        <CheckoutForm details={details} price={formatPrice((totalPrice || 0) + (hasMerch(cartDetails) ? (shipping === "UK mainland" ? 0 : shipping === "European" ? 499 : 1499) : 0))} sessionId={sessionId}/>
                    </Card>
                </Grid>
            </Elements>
        </Page>

    );
}


const CheckoutForm = ({details, price, sessionId}:{details: CheckoutObject, price?:string, sessionId: string}) => {
    const stripe = useStripe();
    const elements = useElements();
    const navigate = useNavigate();

    const handleSubmit = async () => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
        if (!stripe || !elements) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        const result = await stripe.confirmPayment({
            // `Elements` instance that was used to create the Payment Element
            elements,
            confirmParams: {
                // eslint-disable-next-line camelcase
                return_url: `${window.location.origin}/success/${sessionId}`,
                payment_method_data: {
                    billing_details: {
                        address: {
                            line1: details.line1,
                            line2: details.line2 || undefined,
                            postal_code: details.postal_code,
                            country: details.country,
                            city: details.city,
                            state: details.state,
                        },
                        email: details.email,
                        name: details.name,
                    },
                },
                shipping: {
                    address: {
                        line1: details.line1,
                        line2: details.line2 || undefined,
                        postal_code: details.postal_code,
                        country: details.country,
                        city: details.city,
                        state: details.state,
                    },
                    name: details.name,
                },
                receipt_email: details.email,
            },
        });

        if (result.error) {
            // Show error to your customer (for example, payment details incomplete)
            console.log(result.error.message);
        } else {
            // Your customer will be redirected to your `return_url`. For some payment
            // methods like iDEAL, your customer will be redirected to an intermediate
            // site first to authorize the payment, then redirected to the `return_url`.
            navigate(`/success/${sessionId}`);
        }
    };

    return (
        <Form onSubmit={handleSubmit} submitText={`Pay ${price}`} disabled={!stripe}>
            <PaymentElement options={{fields: {billingDetails: {address: {postalCode: "never", country: "never"}}}}}/>
        </Form>
    );
};
