import {ChangeEvent, useEffect, useState} from "react";
import Form from "../../../Components/Form";
import {MusicItem} from "../../../Util/typeDefinitions";
import FileDropzone from "../../../Components/FileDropzone";
import {Button, Divider, Stack, Switch, Typography} from "@mui/material";
import InputGroup from "../../../Components/FormComponents/InputGroup";
import ListMaker from "../../../Components/FormComponents/ListMaker";
import UploadSongList from "../../../Components/FormComponents/UploadSongList";
import {addDoc, collection, doc, updateDoc} from "firebase/firestore";
import {db} from "../../../Util/firebase";
import {uploadFile} from "../../../Util/util";

type Params = {
    id?: string,
    item?: MusicItem,
    onComplete?: (e?: MusicItem) => void,
}

const defaultMusicItem:MusicItem = {
    name: "",
    description: "",
    price: 0,
    stock: 0,
    published: false,
    id: "",
    created: "",
};

export default function UploadMusicForm({id, item, onComplete}:Params) {
    const [formData, setFormData] = useState<MusicItem>(item || defaultMusicItem);
    const [albumUpload, setAlbumUpload] = useState<File|string|undefined>(item?.album);
    const [coverImageUpload, setCoverImageUpload] = useState<File|string|undefined>(item?.image);

    useEffect(() => {
        if (id && item) {
            setFormData(item);
        }
    }, [item]);

    const handleFormSubmit = async () => {
        const songs = {...formData.songs};
        const uploadObject = {
            name: formData.name,
            description: formData.description,
            mixAndProd: formData.mixAndProd,
            performedBy: formData.performedBy,
            writtenBy: formData.writtenBy,
            position: formData.position,
            coverArt: formData.coverArt,
            price: formData.price,
            coverPhoto: formData.coverPhoto,
            coverTaglineArt: formData.coverTaglineArt,
            created: (new Date()).toISOString(),
            songs: Object.fromEntries(Object.entries(formData.songs || {}).map(([key, item]) => {
                const newItem = {...item};
                delete newItem.songFile;
                delete newItem.image;
                return [key, newItem];
            })),
            published: false,
        } as MusicItem;
        console.log("Upload object", uploadObject);
        console.log("Upload album", albumUpload);
        console.log("Upload cover", coverImageUpload);
        console.log("Upload songs", songs);

        let itemId = id;
        if (!id) {
            itemId = (await addDoc(collection(db, "music"), uploadObject)).id;
        }

        if (id) {
            await updateDoc(doc(db, "music", id), uploadObject);
        }
        console.log("COVER" + coverImageUpload, itemId);

        if (coverImageUpload && itemId && typeof coverImageUpload !== "string") {
            await uploadFile(["music", itemId, "cover.webp"], coverImageUpload);
        }
        if (albumUpload && itemId && typeof albumUpload !== "string") {
            await uploadFile(["music", itemId, "album.zip"], albumUpload);
        }

        const songPositions = Object.values(songs || {}).reduce((acc, song) => {
            acc.push(song.position);
            return acc;
        }, [] as any[]);

        if (new Set(songPositions).size !== songPositions.length) {
            throw new Error("Duplicate positions.");
        }

        if (itemId) {
            console.log("UPLOADING SONGS");
            Object.values(songs || {}).forEach(async (song) => {
                console.log("SONG", song);
                if (!itemId) return;
                if (song.image && typeof song.image !== "string") {
                    await uploadFile(["music", itemId, `${song.position}.webp`], song.image);
                }
                if (song.songFile) {
                    if (typeof song.songFile === "string") return;
                    console.log("UPLOADING SONG");
                    await uploadFile(["music", itemId, `${song.position}.wav`], song.songFile).catch(console.error);
                }
            });
        }

        if (formData.published && itemId) {
            // Check if all images uploaded.
            // Check all pricing etc
            // update
            if (!albumUpload) {
                throw new Error("Cannot publish without an album file");
            }
            if (!coverImageUpload) {
                throw new Error("Cannot publish without a cover image");
            }
            if (!formData.price) {
                throw new Error("Cannot publish without an album price");
            }

            Object.values(formData.songs || {}).map((song) => {
                if (!song.songFile) {
                    throw new Error(`Song ${song.position} is missing a .wav file.`);
                }
                if (!song.image) {
                    throw new Error(`Song ${song.position} is missing an image file.`);
                }
                if (!song.price) {
                    throw new Error("Cannot publish without an album price");
                }
            });

            await updateDoc(doc(db, "music", itemId), {published: true});
        }

        onComplete && onComplete();
        return;
    };


    return (
        <Form onSubmit={handleFormSubmit}>
            {formData?.image ?
                <Stack direction={"row"} alignItems={"center"}>
                    <img width={200} style={{margin: "auto"}} src={formData.image}/>
                    <FileDropzone accept="image/webp" onChange={(e) => setCoverImageUpload(e.target.files?.[0])} label="Reupload album cover image" name="albumCover"/>
                </Stack> : <FileDropzone accept="image/webp" onChange={(e) => setCoverImageUpload(e.target.files?.[0])} label="Album cover image" name="albumCover"/>}
            <InputGroup value={formData?.name} onChange={(e: ChangeEvent<HTMLInputElement>) => setFormData((f) => ({...f, name: e.target.value}))} required name={"name"} label={"Album name"}/>

            {formData?.album ?
                <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                    <Button onClick={() => window.open(formData?.album)}>Download uploaded album</Button>
                    <FileDropzone accept="application/zip" onChange={(e) => setAlbumUpload(e.target.files?.[0])} label="Reupload zipped album (.zip containing .wav files)" name="album"/>
                </Stack> : <FileDropzone accept="application/zip" onChange={(e) => setAlbumUpload(e.target.files?.[0])} label="Upload zipped album (.zip containing .wav files)" name="album"/>}

            <InputGroup value={formData?.description} onChange={(e: ChangeEvent<HTMLInputElement>) => setFormData((f) => ({...f, description: e.target.value}))} name={"description"} label={"Description"}/>
            <InputGroup value={formData?.mixAndProd} onChange={(e: ChangeEvent<HTMLInputElement>) => setFormData((f) => ({...f, mixAndProd: e.target.value}))} name={"mixAndProd"} label={"Mixing and Production"}/>
            <InputGroup value={formData?.performedBy || "OMA"} onChange={(e: ChangeEvent<HTMLInputElement>) => setFormData((f) => ({...f, performedBy: e.target.value}))} required name={"performedBy"} label={"Performed by"}/>
            <InputGroup value={formData?.price} required name={"price"} onChange={(e: ChangeEvent<HTMLInputElement>) => setFormData((f) => ({...f, price: parseInt(e.target.value)}))} label={"Price (Pence)"} type={"number"} placeholder={"Type 1500 for £15.00"}/>
            <InputGroup value={formData?.position} required name={"position"} onChange={(e: ChangeEvent<HTMLInputElement>) => setFormData((f) => ({...f, position: parseInt(e.target.value)}))} label={"Position"} type={"number"}/>
            <Divider/>
            <ListMaker items={formData?.writtenBy} name={"writtenBy"} onChange={(e) => setFormData((f) => ({...f, writtenBy: e.join(", ")}))} label={"Written by"}/>
            <InputGroup value={formData?.coverPhoto} name={"coverPhoto"} onChange={(e: ChangeEvent<HTMLInputElement>) => setFormData((f) => ({...f, coverPhoto: e.target.value}))} label={"Cover photo"}/>
            <InputGroup value={formData?.coverTaglineArt} name={"coverTaglineArt"} onChange={(e: ChangeEvent<HTMLInputElement>) => setFormData((f) => ({...f, coverTaglineArt: e.target.value}))} label={"Cover tagline art"}/>
            <Divider/>
            <Typography variant="h6">Add songs</Typography>
            <UploadSongList name="songs" items={formData?.songs} label="Add songs" onChange={(songs) => setFormData((f) => ({...f, songs: songs}))}/>
            <Stack direction={"row"} width={"max-content"} alignItems={"center"} spacing={1} alignSelf={"center"} justifyContent={"space-evenly"}>
                <Typography>Save draft</Typography>
                <Switch name="publish" checked={formData?.published} onChange={(e) => setFormData((f) => ({...f, published: e.target.checked}))}/>
                <Typography>Publish</Typography>
            </Stack>
        </Form>
    );
}
