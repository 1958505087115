import {Upload} from "@mui/icons-material";
import {Alert, Box, Button, Collapse, Stack, Typography, styled} from "@mui/material";
import {ChangeEvent, useEffect, useState} from "react";

type Params = {
    accept: string,
    onSubmit?: (e: File[]) => void,
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void,
    label: string,
    required?: boolean,
    multiple?: boolean,
    name: string,
    defUploadText?: string,
}

const FileDropzoneContainer = styled(Box)`
  padding: 20px;
  width: 300px;
  border: 2px grey dashed;
  border-radius: 4px;
  position: relative;
  display: flex ;
  align-items: center ;
  justify-content: center;
  transition: background-color 250ms cubic-bezier(0.075, 0.82, 0.165, 1);

  &:hover {
    background-color: #ffffff17;
  }
`;

const FileDropzoneInput = styled("input")`
  width: 100%;
  height: 100%;
  opacity: 0;
  position: absolute;
  cursor: pointer;
  z-index: 9;
  top: 0;
  left: 0;
`;

export default function FileDropzone({accept, onSubmit, onChange, label, required, multiple, name, defUploadText=""}: Params) {
    const [uploadText, setUploadText] = useState(label || "Upload a file");
    const [files, setFiles] = useState<File[]>([]);
    const [fileTypeError, setFileTypeError] = useState(false);

    useEffect(() => {
        if (defUploadText) {
            setUploadText(defUploadText);
        }
    }, [defUploadText]);

    const handleUpload = (e: ChangeEvent<HTMLInputElement>) => {
        setFileTypeError(false);


        if (!e.target.files || e.target.files.length === 0) {
            setUploadText((label || "Upload a file"));
            return;
        }

        let fFileTypeError = false;
        const nameList = (Array.from(e.target.files).reduce((acc, val) => {
            if (accept && val.type !== accept) {
                fFileTypeError = true;
            }

            if (!acc.includes(val.name)) {
                acc.push(val.name);
            }
            return acc;
        }, [] as Array<string>)).join(", ");

        if (fFileTypeError) {
            setUploadText((label || "Upload a file"));
            setFiles([]);
            setFileTypeError(true);
            return;
        }

        setUploadText(nameList);
        setFiles(Array.from(e.target.files));
        (onChange !== undefined) && onChange(e);
    };

    return (
        <Stack alignItems='center' spacing={1}>
            <FileDropzoneContainer>
                <FileDropzoneInput type='file' required={required ? uploadText===defUploadText ? false : true : false} name={name} accept={accept} multiple={multiple} onChange={handleUpload}/>
                <input name={""} hidden value={files.length > 0 ? "1" : undefined} required={required ? uploadText===defUploadText ? false : true : false}/>
                <Stack alignItems='center' spacing={0}>
                    {uploadText === "Upload a file" && <Upload/>}
                    <Typography variant='body1' textAlign={"center"}>{uploadText + (required ? "*" : "")}</Typography>
                    <Typography color={"#bbb"}>{uploadText === "Upload a file" && accept && `(${accept})`}</Typography>
                </Stack>
            </FileDropzoneContainer>
            {onSubmit && <Button disabled={required ? files.length > 0 ? false : true : false} onClick={() => onSubmit(files)}>Upload</Button>}
            <Collapse in={fileTypeError}>
                <Alert severity='error'>{accept} required.</Alert>
            </Collapse>
        </Stack>

    );
}
