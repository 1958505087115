import {Stack} from "@mui/material";
import Page from "../Components/Page";
import spotifyLogo from "../Images/Listen_on_Spotify.png";
import bandcampLogo from "../Images/bandcamp-logo.png";
import soundcloudLogo from "../Images/SoundCloud.png";
import youtubeLogo from "../Images/youtube.png";
import tidalLogo from "../Images/tidal.png";
import deezerLogo from "../Images/Deezer.png";
import {useCheckMobileScreen} from "../Util/util";

export default function Stream() {
    const isMobile = useCheckMobileScreen();
    return (
        <Page metaTitle="OMA | Stream" sx={{alignItems: "center"}} title="STREAM">
            <Stack direction={isMobile ? "column" : "row"} spacing={4} flexWrap={"wrap"}>
                <a id="apple-music-button" href="https://music.apple.com/us/artist/oma/1633336452?itsct=music_box_badge&amp;itscg=30200&amp;app=music&amp;ls=1"
                    style={{display: "inline-block", border: "none", overflow: "hidden", borderRadius: "13px", width: "250px", height: "83px"}}><img src="https://tools.applemediaservices.com/api/badges/listen-on-apple-music/badge/en-us?size=250x83" alt="Listen on Apple Music" style={{borderRadius: "13px", width: "250px", height: "83px"}}/></a>
                <a id="spotify-button" href="https://open.spotify.com/artist/6tcltBYoeWDm2P6NoawNIT"
                    style={{display: "flex", border: "solid 1.5px #a6a6a6", overflow: "hidden", marginTop: "5px", borderRadius: "13px", width: "250px", height: "71px", padding: "3px", background: "black"}}><img src={spotifyLogo} alt="Listen on Spotify" style={{borderRadius: "13px", height: "63px", margin: "auto"}}/></a>
            </Stack>
            <Stack direction={isMobile ? "column" : "row"} spacing={4}>
                <a id="bandcamp-button" href="https://omauk.bandcamp.com/music"
                    style={{display: "flex", border: "solid 1.5px #a6a6a6", overflow: "hidden", marginTop: "5px", borderRadius: "13px", width: "250px", height: "71px", padding: "3px", background: "black"}}><img src={bandcampLogo} alt="Listen on Bandcamp" style={{borderRadius: "13px", height: "63px", margin: "auto"}}/></a>

                <a id="soundcloud-button" href="https://soundcloud.com/oma-758911808?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing"
                    style={{display: "flex", border: "solid 1.5px #a6a6a6", overflow: "hidden", marginTop: "5px", borderRadius: "13px", width: "250px", height: "71px", padding: "3px", background: "black"}}><img src={soundcloudLogo} alt="Listen on Soundcloud" style={{borderRadius: "13px", height: "63px", margin: "auto"}}/></a>
            </Stack>
            <Stack direction={isMobile ? "column" : "row"} spacing={4}>
                <a id="youtube-button" href="https://www.youtube.com/@oma_band"
                    style={{display: "flex", border: "solid 1.5px #a6a6a6", overflow: "hidden", marginTop: "5px", borderRadius: "13px", width: "250px", height: "71px", padding: "3px", background: "black"}}><img src={youtubeLogo} alt="Listen on Bandcamp" style={{borderRadius: "13px", height: "63px", margin: "auto"}}/></a>

                <a id="tidal-button" href="https://tidal.com/browse/artist/4283936"
                    style={{display: "flex", border: "solid 1.5px #a6a6a6", overflow: "hidden", marginTop: "5px", borderRadius: "13px", width: "250px", height: "71px", padding: "3px", background: "black"}}><img src={tidalLogo} alt="Listen on Soundcloud" style={{borderRadius: "13px", height: "63px", margin: "auto"}}/></a>
            </Stack>
            <Stack direction={isMobile ? "column" : "row"} spacing={4}>
                <a id="deezer-button" href="https://www.deezer.com/en/artist/1246114"
                    style={{display: "flex", border: "solid 1.5px #a6a6a6", overflow: "hidden", marginTop: "5px", borderRadius: "13px", width: "250px", height: "71px", padding: "3px", background: "black"}}><img src={deezerLogo} alt="Listen on Bandcamp" style={{borderRadius: "13px", height: "63px", margin: "auto"}}/></a>
            </Stack>
        </Page>
    );
}
