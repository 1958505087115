import {Button, Table, TableBody, TableHead, TableRow, Typography, capitalize} from "@mui/material";
import Page from "../Components/Page";
import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {getDownloadURL, ref} from "firebase/storage";
import {db, storage} from "../Util/firebase";
import {SuccessTableCell, camelCaseToNormal, formatPrice, useCheckMobileScreen} from "../Util/util";
import {doc, getDoc} from "firebase/firestore";
import {Order as OrderType} from "../Util/typeDefinitions";


export default function Order() {
    const [order, setOrder] = useState<OrderType>();
    const {id} = useParams();
    const isMobile = useCheckMobileScreen();

    useEffect(() => {
        if (!id) return;

        getDoc(doc(db, `orders/${id}`)).then((order) => {
            const orderData = order.data() as OrderType;

            Promise.all(orderData.items.map(async (item, i) => {
                const idArray = item.firestoreId.split("-");
                if (idArray[0] === "music") {
                    const downloadUrl = await getDownloadURL(ref(storage, `music/${idArray[1]}/${idArray[2] ? `${idArray[2]}.wav` : "album.zip"}`));
                    orderData.items[i].url = downloadUrl;
                }
            })).then(() => setOrder(orderData));
        });
    }, []);
    return (
        <Page metaTitle="OMA | Success" title="ORDER COMPLETE" sx={{width: "800px", maxWidth: "100vw", margin: "auto"}}>
            <Typography variant="h3" sx={{marginTop: "50px !important"}} textAlign={"center"}>Thank you for your order.</Typography>
            <Typography>For digital items, this is your download page. Bookmark this link for future reference.</Typography>
            <Typography variant="h6">Order summary</Typography>
            <Table>
                <TableHead>
                    <SuccessTableCell sx={{fontWeight: "bold"}}>Item</SuccessTableCell>
                    <SuccessTableCell sx={{fontWeight: "bold"}}>Quantity</SuccessTableCell>
                    {isMobile || <SuccessTableCell sx={{fontWeight: "bold"}}>Unit price</SuccessTableCell>}
                    <SuccessTableCell sx={{fontWeight: "bold"}}>Total price</SuccessTableCell>
                    <SuccessTableCell sx={{fontWeight: "bold"}}>Status</SuccessTableCell>
                </TableHead>
                <TableBody>
                    {order?.items && Object.entries(order?.items).map(([key, item]) =>
                        <TableRow key={key}>
                            <SuccessTableCell>{item.itemDesc}</SuccessTableCell>
                            <SuccessTableCell>{item.quantity}</SuccessTableCell>
                            {isMobile || <SuccessTableCell>{formatPrice(item.unitPrice)}</SuccessTableCell>}
                            <SuccessTableCell>{formatPrice(item.quantity*item.unitPrice)}</SuccessTableCell>
                            <SuccessTableCell>{item.type === "music" ? <Button onClick={() => window.open(item.url)}>Download</Button> : <Typography>{capitalize(camelCaseToNormal(order.shipmentStatus))}</Typography>}</SuccessTableCell>
                        </TableRow>)}
                </TableBody>
            </Table>
        </Page>
    );
}
