import {Box, Stack, Typography} from "@mui/material";
import Page from "../Components/Page";
import hero from "../Images/OMA_all.png";
import {PRIMARY_COLOR} from "..";

export default function About() {
    return (
        <Page metaTitle="OMA | About" noGutters>
            <Box component={"img"} width={"100%"} alignSelf={"center"} src={hero}/>
            <Typography variant="h4" textAlign={"center"} p={5}>ABOUT</Typography>
            <Stack width={800} maxWidth={"calc(100% - 20px)"} alignSelf={"center"}>
                <Typography variant="h6">Band</Typography>
                <Typography><span style={{fontWeight: "bold"}}>Corben Lamb: </span>Keys, Production and Mixing</Typography>
                <Typography><span style={{fontWeight: "bold"}}>Chris Larcombe: </span>Guitar</Typography>
                <Typography><span style={{fontWeight: "bold"}}>Sam Heeley: </span>Drums</Typography>
                <Typography><span style={{fontWeight: "bold"}}>James Harper: </span>Bass</Typography>
                <br/>
                <Typography variant="h6">Contact</Typography>
                <Typography><span style={{fontWeight: "bold"}}>Booking/MGMT: </span><a style={{color: PRIMARY_COLOR}} href={"mailto:Mattmiller@txdxe.com"}>Mattmiller@txdxe.com</a></Typography>
                <Typography><span style={{fontWeight: "bold"}}>General email: </span><a style={{color: PRIMARY_COLOR}} href={"mailto:oma@thesoundparlour.com"}>oma@thesoundparlour.com</a></Typography>
                <br/>
                <Typography variant="h6">Endorsements and Affiliations</Typography>
                <Typography>Sandberg Guitars</Typography>
                <Typography>British Drum Company</Typography>
                <Typography>True North Pedals</Typography>
                <Typography>Etwood Guitar Straps</Typography>
                <br/>
                <Typography variant="h6">Equipment</Typography>
                <Typography>UAD Apollo Twin</Typography>
                <Typography>Nord Stage 3</Typography>
                <Typography>Yamaha DX-21</Typography>
                <Typography>Moog Sub 37</Typography>
                <Typography>Moog Grandmother</Typography>
                <Typography>Fender Telecaster (with B-Bender mod)</Typography>
                <Typography>Fender Stratocaster</Typography>
                <Typography>Fender Deluxe Reverb 65" Reissue</Typography>
                <Typography>Fender Active V Deluxe</Typography>
                <Typography>Sandberg California VM-4</Typography>
                <Typography>Mark Bass Little Mark 300</Typography>
                <Typography>Daddario NYXL strings</Typography>
                <Typography>British Drum Company Legend Series</Typography>
                <Typography>Meinl Byzance 14" Sand Hats</Typography>
                <Typography>Meinl Byzance 16" Dual Crash</Typography>
                <Typography>Meinl Byzance 18" Sand Medium Crash</Typography>
                <Typography>Meinl Byzance Foundry Reserve 22" Ride</Typography>
                <Typography>Vic Firth 5A Barrell Tips</Typography>
                <Typography>Evans UV Heads</Typography>
            </Stack>

        </Page>
    );
}
