// Import the functions you need from the SDKs you need
import {initializeApp} from "firebase/app";
import {initializeFirestore} from "firebase/firestore";
import {getStorage} from "firebase/storage";

import {/* // connectFunctionsEmulator, */getFunctions} from "firebase/functions";
import {getAuth} from "firebase/auth";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDYh_nx5f1wXb3eCP04GvymTxQYcLSHZtM",
    authDomain: "oma-band-website.firebaseapp.com",
    projectId: "oma-band-website",
    storageBucket: "oma-band-website.appspot.com",
    messagingSenderId: "1017397617509",
    appId: "1:1017397617509:web:d56cb4bcee20bd59dc778c",
    measurementId: "G-1VS4LD6XR7",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = initializeFirestore(app, {
    ignoreUndefinedProperties: true,
});
export const functions = getFunctions(app);
export const storage = getStorage(app);
export const auth = getAuth(app);

// connectFunctionsEmulator(functions, "localhost", 5001);
