
import {Box, Grid, ListItem, ListItemText, Stack, Typography, styled} from "@mui/material";
import {ReactNode, useEffect, useRef, useState} from "react";
import {Helmet} from "react-helmet";
import {useLocation, useNavigate} from "react-router-dom";
import IconButtonPop from "./IconButtonPop";
import {ArrowBack} from "@mui/icons-material";

type Params = {
    children?: ReactNode,
    title?: string,
    subtitle?: ReactNode|string,
    titleSecondary?: ReactNode|string,
    titleUnderline?: boolean,
    metaTitle?: string,
    metaDesc?: string,
    metaKeywords?: string[],
    showTitle?: boolean,
    back?: string|boolean,
    noGutters?: boolean,
    grid?: boolean,
    sitemap?: boolean,
    sx?: {[key:string]: unknown},
    fullHeight?: boolean,
    noOverflow?: boolean
}


export default function Page({children, metaTitle, metaDesc, metaKeywords, title, titleSecondary, back, noGutters, grid, sx, fullHeight=true, subtitle, titleUnderline, noOverflow}:Params) {
    const containerRef = useRef<HTMLDivElement>();
    const [top, setTop] = useState<number>();

    useEffect(() => {
        console.log("Load");
        if (!containerRef.current) return;
        const {y} = containerRef.current.getBoundingClientRect();
        console.log("y", y);
        setTop(y);
    }, [containerRef]);

    return (
        <Box ref={containerRef} id="page" maxWidth={"100vw"} p={`0 ${noGutters ? 0 : "16px"}`} sx={{...sx, overflowX: "hidden"}} flex={1} flexDirection={"column"} display={"flex"} position={"relative"} minHeight={fullHeight ? `calc(100vh - ${top}px)` : undefined} height={"max-content"} maxHeight={noOverflow ? `calc(100vh - ${top}px)` : undefined}>
            {metaTitle && <Helmet>
                <title>{metaTitle}</title>
                <meta name="description" content={metaDesc}/>
                <meta name="keywords" content={metaKeywords?.join(", ")}/>
                <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
                <script async src="https://www.googletagmanager.com/gtag/js?id=G-NW5YMC6JN8"></script>
                <script>
                    {`window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());
                        gtag('config', 'G-1VS4LD6XR7');`}
                </script>
            </Helmet>}
            {(title || titleSecondary) && <PageTitle back={back} secondaryItems={titleSecondary} subtitle={subtitle} titleUnderline={titleUnderline}>{title}</PageTitle>}
            {grid ?
                <Grid container mt={0} pb={2} height={"100%"} flexGrow={1}>
                    {children}
                </Grid> :
                <Stack height={noOverflow ? "0px" : "100%"} flexGrow={1} overflow={noOverflow ? "auto" : undefined}>
                    {children}
                </Stack>
            }
        </Box>
    );
}

const TitleContainer = styled(Box)`
    display: flex;
    align-items:center;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    padding: 40px;
    padding-right: 0px;
    @media (max-width: 768px) {
        padding: 20px;
        flex-direction: column;

        h4{
            width: 100%;
        }

    }
`;


type PageTitleParams = {
    children?: React.ReactNode,
    back?: string | boolean,
    secondaryItems?: React.ReactNode,
    sx?: {[key: string]: unknown},
    subtitle?: ReactNode|string,
    titleUnderline?: boolean
}


export function PageTitle({children, back, secondaryItems, sx, subtitle, titleUnderline}:PageTitleParams) {
    const navigate = useNavigate();
    const location = useLocation();

    const orgNameFromUrl = location.pathname.split("/")[1];

    const navigateBack = () => {
        console.log("B", back, typeof back === "string");
        if (window.history.state.idx !== 0) {
            navigate(-1);
            return;
        }

        if (typeof back === "string") {
            navigate(back);
            return;
        }

        navigate(`/${orgNameFromUrl}/home`, {replace: true}); // the current entry in the history stack will be replaced with the new one with { replace: true }
    };

    if (back) {
        return (
            <ListItem sx={{paddingTop: 4, margin: 0, borderBottom: titleUnderline ? "1px solid lightgrey !important" : undefined}}>
                <IconButtonPop responsive={false} title={"Back"} sx={{marginRight: "10px"}} onClick={() => navigateBack()} key={"back-link"}><ArrowBack/></IconButtonPop>
                <ListItemText sx={{margin: 0}}><Typography variant='h4'>{children}</Typography></ListItemText>
            </ListItem>
        );
    }

    return (
        <TitleContainer sx={sx} borderBottom={titleUnderline ? "1px solid lightgrey !important" : undefined}>
            <Box flex={1}>
                <Typography sx={{width: "max-content"}} variant='h4'>{children}</Typography>
                {subtitle && <Typography>{subtitle}</Typography>}
            </Box>
            {secondaryItems}
        </TitleContainer>
    );
}
