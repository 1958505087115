import {TextField} from "@mui/material";
import {useEffect, useState} from "react";

export default function InputGroup(props) {
    const [valid, setValid] = useState(true);
    const [errorText, setErrorText] = useState("");
    const [value, setValue] = useState(props.value || "");

    useEffect(() => {
        setErrorText(props.errorText || (props.type === "password" ? "Passwords must be alphanumeric, at least 8 characters, and contain upper case and special characters." : "Required"));
    }, [props.errorText]);

    useEffect(() => {
        setValue(props.value || "");
    }, [props.value, props.defaultValue]);

    const isValid = (e) => {
        setValid(true);

        props.onInputEnd && props.onInputEnd(e);

        if (props.required && e.target.value === "") {
            setValid(false);
            return;
        } else {
            if (e.target.value === "") {
                setValid(true);
                return;
            }
            if (props.pattern && !(new RegExp(props.pattern)).test(e.target.value)) {
                setErrorText(props.patternError || "Please use the required format.");
                setValid(false);
                e.target.setCustomValidity(false);
                return;
            }
            setValid(true);
        }

        let match = true;
        if (props.type === "password") {
            match = e.target.value.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,200}$/);
        }

        if (match) {
            setValid(true);
            e.target.setCustomValidity("");
        } else {
            setValid(false);
            e.target.setCustomValidity(false);
        }

        props.onChange && props.onChange(e);
    };

    return (
        <TextField error={!valid || props.error} helperText={(!valid || props.error) ? errorText : ""} autoComplete={props.autocomplete || props.name} onBlur={isValid} {...props} fullWidth value={value} onChange={(e) => {
            setValue(e.target.value); props.onChange && props.onChange(e);
        }} inputProps={{min: props?.min, max: props?.max}} InputLabelProps={props.type==="date" ? {shrink: true} : undefined}/>
    );
}
