import {List, ListItem, ListItemSecondaryAction, ListItemText, Typography} from "@mui/material";
import {ChangeEvent, useEffect, useState} from "react";
import InputGroup from "./InputGroup";
import IconButtonPop from "../IconButtonPop";
import {AddCircleOutline, RemoveCircleOutline} from "@mui/icons-material";
import {getRandomNumber} from "../../Util/util";


type Params = {
    items?: string[] | string,
    name: string,
    label: string,
    subtitle?: string,
    separator?: string,
    required?: boolean,
    onChange?: (e: string[]) => void,
}


export default function ListMaker({items, name, label, subtitle, separator=", ", required, onChange}:Params) {
    const [fItems, setFItems] = useState<{[key:string]:string}>({0: ""});
    const [error, setError] = useState(false);

    useEffect(() => {
        if (!items) return;

        if (typeof items === "string") {
            setFItems(Object.fromEntries(items.split(separator).map((item) => [getRandomNumber(0, 100000000), item])));
            return;
        }
        setFItems(Object.fromEntries(items.map((item) => [getRandomNumber(0, 1000000000), item])));
    }, []);

    useEffect(() => {
        if (!onChange || !fItems) return;
        onChange(Object.entries(fItems).map(([, v]) => v).filter((v) => v));
    }, [fItems]);

    const checkError = () => {
        if (!required) return;
        if (!Object.entries(fItems).map(([, v]) => v).filter((v) => v).join(separator).length) {
            setError(true);
            return;
        }
        setError(false);
    };

    return (
        <List sx={{borderBottom: "1px solid #00000050"}}>
            <input hidden required={required} name={name} value={Object.entries(fItems).map(([, v]) => v).filter((v) => v).join(separator)}/>
            <ListItem sx={{padding: 0, marginBottom: 2}}>
                <ListItemText
                    primary={<Typography color={error ? "error" : "inherit"} variant={"subtitle1"}>{label}&nbsp;{required ? <sup>*</sup> : ""}</Typography>}
                    secondary={subtitle}
                    sx={{paddingRight: "70px"}}
                />
                <ListItemSecondaryAction>
                    <IconButtonPop responsive={false} title="Add item" onClick={() => setFItems((i) => ({...i, [getRandomNumber(0, 1000)]: ""}))}><AddCircleOutline color="primary"/></IconButtonPop>
                </ListItemSecondaryAction>
            </ListItem>
            {Object.entries(fItems)?.map(([k, v]) =>
                <ListItem key={k} sx={{padding: 0}}>
                    <ListItemText sx={{paddingRight: "70px"}}>
                        <InputGroup onBlur={checkError} value={v} placeholder={"Start typing..."} onChange={(e:ChangeEvent<HTMLInputElement>) => setFItems((i) => ({...i, [k]: e.target.value}))}/>
                    </ListItemText>
                    <ListItemSecondaryAction>
                        <IconButtonPop responsive={false} title="Remove" onClick={() => setFItems((i) => Object.fromEntries(Object.entries(i).filter(([key]) => key !== k)))}><RemoveCircleOutline/></IconButtonPop>
                    </ListItemSecondaryAction>
                </ListItem>
            )}
        </List>
    );
}
