import {Breakpoint, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material";
import {MouseEventHandler, MutableRefObject, ReactNode} from "react";
import IconButtonPop from "./IconButtonPop";
import {Close} from "@mui/icons-material";


type Params = {
    open: boolean,
    onClose: (event?: object, reason?: string) => void,
    title?: string|ReactNode,
    children: ReactNode,
    actions?: ReactNode,
    maxWidth?: false | Breakpoint,
    fullWidth?: boolean
    sx?: {[key:string]: unknown},
    cardSx?: {[key:string]:unknown},
    onMouseMove?: MouseEventHandler<HTMLDivElement>,
    id?: string,
    noCloseButton?: boolean,
    cardRef?: MutableRefObject<unknown>
}

export function Popup({open, onClose, title, children, actions, maxWidth, fullWidth, sx, cardSx, onMouseMove, id, noCloseButton, cardRef}:Params) {
    return (
        <Dialog {...{open, onClose, maxWidth, fullWidth, sx, onMouseMove, id}}>
            <DialogTitle sx={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center"}}>{typeof title === "string" ? <Typography variant={"h5"} textOverflow={"ellipsis"} overflow={"hidden"} maxWidth={"calc(100% - 40px)"}>{title}</Typography> : title}{noCloseButton || <IconButtonPop responsive={false} title={"Close"} onClick={onClose} sx={{float: "right"}}><Close/></IconButtonPop>}</DialogTitle>
            <DialogContent sx={cardSx} ref={cardRef}>
                {children}
            </DialogContent>
            {actions && <DialogActions>{actions}</DialogActions>}
        </Dialog>
    );
}
