import {FormHelperText, TextField, TextFieldPropsSizeOverrides} from "@mui/material";
import {OverridableStringUnion} from "@mui/types";
import {ChangeEvent, ReactNode, useEffect, useState} from "react";


type Params = {
    value?: string|string[],
    onChange?: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
    children?: ReactNode,
    disabled?: boolean,
    required?: boolean,
    multiple?: boolean,
    name?: string,
    size?: OverridableStringUnion<"small" | "medium", TextFieldPropsSizeOverrides>,
    label?: string,
    sx?: {[key:string]: unknown},
    errorText?: string,
    id?: string
}

export default function Dropdown({id, value, onChange, children, disabled, required, multiple, name, size="small", label, sx, errorText}:Params) {
    const [selected, setSelected] = useState(value ? multiple ? Array.isArray(value) ? value : value.split(",") : value : (name === "country" ? "unitedKingdom" : undefined));
    const [error, setError] = useState(false);
    const [items, setItems] = useState(children);

    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (e.target.value === "" && required) {
            setError(true);
        } else {
            setError(false);
        }

        onChange ? onChange(e) : setSelected(e.target.value);
    };

    useEffect(() => {
        setSelected(multiple ? typeof value === "string" ? value.split(",") : value : (value || (name === "country" ? "unitedKingdom" : undefined)));
    }, [multiple, value]);

    useEffect(() => {
        setItems(children || "");
    }, [children, name]);

    console.log(id, selected);
    return (
        <TextField fullWidth select sx={sx} label={`${label}${required ? " *" : ""}`}
            SelectProps={{
                multiple: multiple,
                value: ((multiple && !selected) ? [] : selected),
                required: required,
            }}
            error={error}
            onBlur={(e) => handleChange(e)}
            onChange={handleChange}
            name={name}
            size={size}
            disabled={disabled}
            id={id}
        >
            {items}
            <FormHelperText error={error}>{error ? (errorText || "Required") : ""}</FormHelperText>
        </TextField>
    );
}
