import {Stack, Typography} from "@mui/material";
import Page from "../Components/Page";
import {useCheckMobileScreen} from "../Util/util";
import {useNavigate} from "react-router-dom";
import {RenderStoreItems} from "./Store";


export default function Home() {
    const isMobile = useCheckMobileScreen();
    const navigate = useNavigate();

    return (
        <Page metaTitle="OMA | Home" fullHeight noGutters>
            <Stack minHeight={"500px"} sx={{backgroundImage: "url(https://firebasestorage.googleapis.com/v0/b/oma-band-website.appspot.com/o/public%2FGroup%20Shot%201%204mb.webp?alt=media&token=5b85ba41-9c3d-4026-bf5c-a60ca08e85f5)",
                backgroundPosition: "0 -120px",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat"}} overflow={"hidden"} borderBottom={"5px white solid"} direction={isMobile ? "column-reverse" : "row"} position={"relative"} display={"flex"} maxWidth={"100%"}>
                <div style={{position: "absolute", height: "100%", width: "100%", background: "#0000006b"}}/>
                <Stack flex={1} zIndex={100} spacing={2} p={isMobile ? 5 : 10} justifyContent={"center"}>
                    <Typography variant="h2" fontWeight={"bold"}>OFFICIAL OMA MERCH</Typography>
                    <Typography variant="h5" fontWeight={"bold"}>OUT NOW</Typography>
                    <Typography variant="h6" fontWeight={"bold"} textAlign={"end"} sx={{cursor: "pointer"}} onClick={() => navigate("/store/merch")}>VIEW MERCH →</Typography>
                </Stack>
            </Stack>
            <Typography variant="h4" textAlign={"center"} mb={5} fontWeight={"bold"} pt={5}>MERCH</Typography>
            <RenderStoreItems type={"merch"}/>
            <Typography variant="h4" textAlign={"center"} mb={5} fontWeight={"bold"} pt={5}>MUSIC</Typography>
            <RenderStoreItems type={"music"}/>
        </Page>
    );
}
