import {Link, useLocation, useNavigate} from "react-router-dom";
import {Badge, Box, ListItem, ListItemIcon, ListItemText, Stack, capitalize, css, keyframes, styled} from "@mui/material";
import {useShoppingCart} from "use-shopping-cart";
import IconButtonPop from "./IconButtonPop";
import {Album, Analytics, Event, Groups, LocalOffer, LocalPostOffice, Logout, Money, PlayCircle, ShoppingCart, Warehouse} from "@mui/icons-material";
import {useCheckMobileScreen} from "../Util/util";
import logo from "../Images/Logo.png";
import {ReactNode, useEffect, useRef, useState} from "react";
import {auth} from "../Util/firebase";
import {PRIMARY_COLOR} from "..";


const NavBarLink = styled(Link)<{$active: boolean}>(({theme, $active}) => ({
    "textDecoration": "none",
    "color": $active ? theme.palette.primary.main : "white",
    "paddingLeft": theme.spacing(2),
    "paddingRight": theme.spacing(2),
    "fontWeight": "bold",
    "&:hover": {
        textDecoration: "underline",
    }}));

const NavBarHolder = styled(Box)`
    width: 100%;
    height: ${(props:{isMobile: boolean}) => props.isMobile ? "60px" : "80px"};
    text-align: center;
    display: flex;
    flex-direction: column;
    z-index: 1201;
    position: fixed;
    background-color: black;
`;


const SideBarItem = styled(ListItem)<{$active: boolean}>(({theme, $active}) => ({
    "backgroundColor": "none",
    "color": $active ? theme.palette.primary.main : "none",
    "overflow": "hidden",
    "cursor": "pointer",
    "transition": "all 500ms ease",
    "display": "flex",
    "flexDirection": "column",
    "padding": "10px 0 0 0",
    "&:hover": {
        backgroundColor: $active ? undefined : "rgba(0, 0, 0, 0.10)",
    },
    "div": {
        "padding": 0,
        "color": $active ? theme.palette.primary.main : undefined,
    },
    "span": {
        "fontSize": "12px",
    },
}));

const calculateScrollWidth = (textWidth: number, windowWidth: number) => {
    return textWidth > windowWidth ? textWidth - windowWidth : 0;
};

const bounce = (scrollWidth: number) => keyframes`
    0% {
      transform: translateX(0);
    }
    50% {
      transform: translateX(-${scrollWidth+10}px);
    }
    100% {
      transform: translateX(0);
    }
  `;

const BannerContainer = styled("div")<{isMobile: boolean, color: string}>`
  position: fixed;
  top: ${({isMobile}) => (isMobile ? "60px" : "80px")};
  width: 100%;
  z-index: 100000;
  background-color: ${({color}) => color};
  color: black;
  padding: 8px 0;
  border-bottom: 1px solid white;
  overflow: hidden;
  white-space: nowrap;
`;

const BannerContent = styled("div")<{ shouldAnimate: boolean; scrollWidth: number }>`
  display: inline-block;
  padding-left: 10px;
  padding-right: 10px;
  ${({shouldAnimate, scrollWidth}) =>
        shouldAnimate ?
            css`
          animation: ${bounce(scrollWidth)} 10s linear infinite;
        ` :
            css`
          padding-left: 0;
        width: 100% !important;
          text-align: center;
      `}
`;

function Banner() {
    const [isMobile, setIsMobile] = useState(false);
    const [shouldAnimate, setShouldAnimate] = useState(false);
    const [scrollWidth, setScrollWidth] = useState(0);
    const textRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 540);
            if (textRef.current) {
                const textWidth = textRef.current.scrollWidth;
                const windowWidth = window.innerWidth;
                const calculatedScrollWidth = calculateScrollWidth(textWidth, windowWidth);
                setScrollWidth(calculatedScrollWidth);
                setShouldAnimate(calculatedScrollWidth > 0);
            }
        };

        handleResize(); // Set initial value
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <BannerContainer isMobile={isMobile} color={PRIMARY_COLOR}>
            <BannerContent ref={textRef} shouldAnimate={shouldAnimate} scrollWidth={scrollWidth}>
            Free shipping on all UK mainland orders! • Hoodie and T-Shirt orders are closed until October.
            </BannerContent>
        </BannerContainer>
    );
}


export default function NavBar({children}:{children: ReactNode}) {
    const {cartCount} = useShoppingCart();
    const isMobile = useCheckMobileScreen() || false;
    const navigate = useNavigate();
    const location = useLocation();
    const url = location.pathname.split("/");

    const signOut = () => {
        auth.signOut();
    };

    const isAdmin = window.location.href.split("/")[3] === "admin";
    console.log("admin", isAdmin, window.location.href.split("/")[3]);

    if (isAdmin) {
        return (
            <><NavBarHolder isMobile={isMobile} sx={{borderBottom: "white 1px solid"}}>
                <Stack m={"auto"} height={"100%"} maxWidth={"100%"} width={"600px"} direction={"row"} id={"link-holder"} flex={1} justifyContent={"space-around"} alignItems={"center"} flexWrap={"wrap"}>
                    {isMobile || <NavBarLink key={"orders"} $active={url[1] === "orders"} to="/admin/orders">ORDERS</NavBarLink>}
                    {isMobile || <NavBarLink key={"items"} $active={url[1] === "items"} to="/admin/items">ITEMS</NavBarLink>}
                    <Box sx={{cursor: "pointer"}} onClick={() => navigate("/admin/home")} component={"img"} src={logo} height={"100%"}/>
                    {isMobile || <NavBarLink key={"money"} $active={url[1] === "money"} to="/money">MONEY</NavBarLink>}
                    {isMobile || <NavBarLink key={"analytics"} $active={url[1] === "analytics"} to="/admin/analytics">ANALYTICS</NavBarLink>}
                </Stack>
                <Box position={"absolute"} right={"20px"} top={"50%"} sx={{transform: "translateY(-50%)"}}>
                    <IconButtonPop responsive={false} title="Logout" onClick={signOut}>
                        <Logout/>
                    </IconButtonPop>
                </Box>
            </NavBarHolder>
            <Box mt={isMobile ? "60px" : "80px"} pb={15}>
                {children}
            </Box>

            {isMobile &&
        <NavBarHolder bottom={0} isMobile={isMobile}>
            <Stack spacing={0} direction={"row"} flex={1} justifyContent={"space-around"} alignItems={"center"}>
                {[["Orders", <LocalPostOffice/>], ["Items", <Warehouse/>], ["Home", <PlayCircle/>], ["Money", <Money/>], ["Analytics", <Analytics/>]].map(([item, icon]) =>
                    <SideBarItem $active={url[1] === (item as string).toLowerCase() || !url[1] && (item as string).toLowerCase() === "home"} onClick={() => {
                        navigate(`/${(item as string).toLowerCase()}`);
                    }} key={`${item}-label`}>
                        <ListItemIcon sx={{minWidth: 0}}>{icon}</ListItemIcon>
                        <ListItemText primary={capitalize(item as string)} />
                    </SideBarItem>)}
            </Stack>
        </NavBarHolder>}
            </>
        );
    }

    return (
        <>
            <NavBarHolder isMobile={isMobile} sx={{borderBottom: "white 1px solid"}}>
                <Stack m={"auto"} height={"100%"} maxWidth={"100%"} width={"600px"} direction={"row"} id={"link-holder"} flex={1} justifyContent={"space-around"} alignItems={"center"} flexWrap={"wrap"}>
                    {isMobile || <NavBarLink sx={{width: "100px"}} key={"stream"} $active={url[1] === "stream"} to="/stream">STREAM</NavBarLink>}
                    {isMobile || <NavBarLink sx={{width: "100px"}} key={"merch"} $active={url[1] === "store"} to="/store">STORE</NavBarLink>}
                    <Box sx={{cursor: "pointer"}} onClick={() => navigate("/home")} component={"img"} src={logo} height={"100%"}/>
                    {isMobile || <NavBarLink sx={{width: "100px"}} key={"events"} $active={url[1] === "gigs"} to="/gigs">GIGS</NavBarLink>}
                    {isMobile || <NavBarLink sx={{width: "100px"}} key={"about"} $active={url[1] === "about"} to="/about">ABOUT</NavBarLink>}
                </Stack>
                <Box position={"absolute"} right={"20px"} top={"50%"} sx={{transform: "translateY(-50%)"}}>
                    <IconButtonPop responsive={false} title="Cart" onClick={() => navigate("/basket")}>
                        <Badge badgeContent={cartCount} color="primary">
                            <ShoppingCart/>
                        </Badge>
                    </IconButtonPop>
                </Box>
            </NavBarHolder>
            <Banner/>
            <Box mt={isMobile ? "96px" : "116px"} pb={15}>
                {children}
            </Box>

            {isMobile &&
            <NavBarHolder bottom={0} isMobile={isMobile}>
                <Stack spacing={0} direction={"row"} flex={1} justifyContent={"space-around"} alignItems={"center"}>
                    {[["Stream", <Album/>], ["Store", <LocalOffer/>], ["Home", <PlayCircle/>], ["Gigs", <Event/>], ["About", <Groups/>]].map(([item, icon]) =>
                        <SideBarItem $active={url[1] === (item as string).toLowerCase() || !url[1] && (item as string).toLowerCase() === "home"} onClick={() => {
                            navigate(`/${(item as string).toLowerCase()}`);
                        }} key={`${item}-label`}>
                            <ListItemIcon sx={{minWidth: 0}}>{icon}</ListItemIcon>
                            <ListItemText primary={capitalize(item as string)} />
                        </SideBarItem>)}
                </Stack>
            </NavBarHolder>}
        </>
    );
}
