import Page from "../Components/Page";
import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import Preloader from "../Components/Preloader";
import {Stack, Typography} from "@mui/material";
import {collection, getDocs, query, where} from "firebase/firestore";
import {db} from "../Util/firebase";
import {useShoppingCart} from "use-shopping-cart";


export default function Success() {
    const {id} = useParams();
    const navigate = useNavigate();
    const {clearCart} = useShoppingCart();

    const [attempt, setAttempt] = useState(1);

    useEffect(() => {
        clearCart();
    }, []);

    useEffect(() => {
        if (!id) return;

        getDocs(query(collection(db, "orders"), where("stripeId", "==", id))).then((docs) => {
            if (docs.empty) {
                if (attempt === 30) {
                    throw new Error("Could not find order. Please email us and we'll look into the problem.");
                }
                setTimeout(function() {
                    setAttempt((a) => a+1);
                }, 1000);
            }
            navigate(`/order/${docs.docs[0].id}`);
        }).catch(() => undefined);
    }, [attempt]);

    return (
        <Page metaTitle="OMA | Processing" sx={{width: "800px", maxWidth: "100vw", margin: "auto"}}>
            <Stack alignItems={"center"} m={"auto"}>
                <Typography variant="h4">Processing...</Typography>
                <Preloader visible size={80}/>
            </Stack>
        </Page>
    );
}
