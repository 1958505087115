import {Button, List, ListItem, ListItemSecondaryAction, ListItemText, Stack, Typography} from "@mui/material";
import {ChangeEvent, useEffect, useState} from "react";
import InputGroup from "./InputGroup";
import IconButtonPop from "../IconButtonPop";
import {AddCircleOutline, RemoveCircleOutline} from "@mui/icons-material";
import {Song} from "../../Util/typeDefinitions";
import FileDropzone from "../FileDropzone";
import {PRIMARY_COLOR} from "../..";


type Params = {
    items?: {
        [song: string]: Song
    },
    name: string,
    label: string,
    subtitle?: string,
    separator?: string,
    required?: boolean,
    onChange?: (e: {
        [song: string]: Song
    }) => void,
}


export default function UploadSongList({items, label, subtitle, separator=", ", required, onChange}:Params) {
    const [fItems, setFItems] = useState<{[song: string]: Song}>({});
    const [error, setError] = useState(false);

    useEffect(() => {
        if (!items) return;

        setFItems(items);
    }, []);

    useEffect(() => {
        if (!onChange || !fItems) return;
        onChange(fItems);
    }, [fItems]);

    const checkError = () => {
        if (!required) return;
        if (!Object.entries(fItems).map(([, v]) => v).filter((v) => v).join(separator).length) {
            setError(true);
            return;
        }
        setError(false);
    };

    return (
        <List sx={{borderBottom: "1px solid #00000050"}}>
            <ListItem sx={{padding: 0, marginBottom: 2}}>
                <ListItemText
                    primary={<Typography color={error ? "error" : "inherit"} variant={"subtitle1"}>{label}&nbsp;{required ? <sup>*</sup> : ""}</Typography>}
                    secondary={subtitle}
                    sx={{paddingRight: "70px"}}
                />
                <ListItemSecondaryAction>
                    <IconButtonPop responsive={false} title="Add song" onClick={() => setFItems((i) => ({...i, [Object.keys(i).length + 1]: {}}))}><AddCircleOutline color="primary"/></IconButtonPop>
                </ListItemSecondaryAction>
            </ListItem>
            {Object.entries(fItems)?.map(([k, v]) =>
                <ListItem key={k} sx={{padding: 0, borderTop: `${PRIMARY_COLOR} 1px solid`, paddingTop: 2, paddingBottom: 2}}>
                    <ListItemText sx={{paddingRight: "70px"}}>
                        <Stack spacing={1}>
                            <InputGroup onBlur={checkError} value={v.name} required label={"Song name"} onChange={(e:ChangeEvent<HTMLInputElement>) => setFItems((i) => ({...i, [k]: {...i[k], name: e.target.value}}))}/>
                            <InputGroup onBlur={checkError} value={v.duration} required label={"Duration"} placeholder={"1:25"} onChange={(e:ChangeEvent<HTMLInputElement>) => setFItems((i) => ({...i, [k]: {...i[k], duration: e.target.value}}))}/>
                            <InputGroup onBlur={checkError} value={v.price} required label={"Price (Pence)"} type={"number"} placeholder={"Type 1500 for £15.00"} onChange={(e:ChangeEvent<HTMLInputElement>) => setFItems((i) => ({...i, [k]: {...i[k], price: parseInt(e.target.value)}}))}/>
                            <InputGroup onBlur={checkError} value={v.position} required label={"Position"} type={"number"} onChange={(e:ChangeEvent<HTMLInputElement>) => setFItems((i) => ({...i, [k]: {...i[k], position: parseInt(e.target.value)}}))}/>
                            {v?.image && typeof v.image === "string" ?
                                <Stack direction={"row"} alignItems={"center"}>
                                    <img width={200} style={{margin: "auto"}} src={typeof v.image === "string" ? v.image : undefined}/>
                                    <FileDropzone onChange={(e) => setFItems((i) => ({...i, [k]: {...i[k], image: e.target.files?.[0]}}))} accept="image/webp" label="Reupload song image" name="image"/>
                                </Stack> : <FileDropzone accept="image/webp" onChange={(e) => setFItems((i) => ({...i, [k]: {...i[k], image: e.target.files?.[0]}}))} label="Song image" name="image"/>}

                            {v.songFile && typeof v.songFile === "string" ?
                                <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                                    <Button onClick={() => window.open(typeof v.songFile === "string" ? v.songFile : undefined)}>Download uploaded song</Button>
                                    <FileDropzone accept="audio/wav" onChange={(e) => setFItems((i) => ({...i, [k]: {...i[k], songFile: e.target.files?.[0]}}))} label="Reupload song (.wav)" name="songFile"/>
                                </Stack> : <FileDropzone accept="audio/wav" onChange={(e) => setFItems((i) => ({...i, [k]: {...i[k], songFile: e.target.files?.[0]}}))} label="Upload song (.wav)" name="songFile"/>}
                        </Stack>
                    </ListItemText>
                    <ListItemSecondaryAction>
                        <IconButtonPop responsive={false} title="Remove" onClick={() => setFItems((i) => Object.fromEntries(Object.entries(i).filter(([key]) => key !== k)))}><RemoveCircleOutline/></IconButtonPop>
                    </ListItemSecondaryAction>
                </ListItem>
            )}
        </List>
    );
}
