import {useEffect} from "react";
import Page from "../Components/Page";


export default function Gigs() {
    useEffect(() => {
        const script = document.createElement("script");
        script.src = "//widget-app.songkick.com/injector/10232122";
        document.getElementById("gig-container").appendChild(script);
    }, []);


    return (
        <Page metaTitle="OMA | Gigs" title="GIGS" fullHeight>
            <div id="gig-container">
                <a href="https://www.songkick.com/artists/10232122" className="songkick-widget" data-theme="dark" data-track-button="on" data-detect-style="on" data-locale="en" data-other-artists="on" data-share-button="on" data-country-filter="on" data-rsvp="on" data-request-show="on" data-past-events="on" data-past-events-offtour="on" data-remind-me="on"></a>
            </div>
        </Page>
    );
}
