import {Typography} from "@mui/material";
import Page from "../Components/Page";

export default function Cancel() {
    return (
        <Page metaTitle="OMA | Cancel" title="ORDER CANCELLED" noGutters>
            <Typography variant="h3" sx={{marginTop: "50px !important"}} textAlign={"center"}>Your order has not gone through.</Typography>
        </Page>
    );
}
