import {Box, Button, ButtonGroup, Stack} from "@mui/material";
import {useShoppingCart} from "use-shopping-cart";
import {useCheckMobileScreen} from "../Util/util";
import {ShoppingBasket} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";

export type CartMerchItem = {
    id: string;
    name: string;
    description: string;
    price: number;
    stock?: number | undefined;
}

export default function AddToCart({item, disabled, alignLeft, basketButton}:{item: CartMerchItem, disabled?: boolean, alignLeft?: boolean, basketButton?: boolean|"icon"}) {
    const {cartDetails, addItem, incrementItem, decrementItem} = useShoppingCart();
    const isMobile = useCheckMobileScreen();
    const itemCount = cartDetails?.[item.id]?.quantity;
    const navigate = useNavigate();

    const itemType = item.id.split("-")[0];

    return (
        <Box sx={{float: alignLeft ? "left" : "right"}}>
            {itemCount ?
                <Stack direction={"row"} spacing={1}>
                    <ButtonGroup>
                        {itemType === "music" ? <Button onClick={(e) => {
                            decrementItem(item.id);
                            e.stopPropagation();
                        }}>Remove</Button> : <>
                            <Button sx={{marginRight: "0px !important", minWidth: isMobile ? "35px !important" : undefined}} disabled={disabled} onClick={(e) => {
                                decrementItem(item.id);
                                e.stopPropagation();
                            }}>-</Button>
                            <Box sx={{width: isMobile ? "25px" : "38px", height: "36.5px", border: "1px solid rgba(87, 240, 167, 0.5)", borderRight: "transparent", marginLeft: "-1px", textAlign: "center", color: "rgb(87, 240, 167)", lineHeight: "36.5px", borderRadius: "1px"}}>{itemCount}</Box>
                            <Button sx={{minWidth: isMobile ? "35px !important" : undefined}} disabled={disabled || itemCount === item.stock} onClick={(e) => {
                                incrementItem(item.id);
                                e.stopPropagation();
                            }}>+</Button>
                        </>}
                    </ButtonGroup>
                    {basketButton && <Button sx={{"height": "auto", "> .MuiButton-icon": {mr: "0px !important"}}} startIcon={(isMobile || basketButton === "icon") && <ShoppingBasket/>} onClick={() => navigate("/basket")} color={"primary"}>{(isMobile || basketButton === "icon") ? "" : "Go to basket"}</Button>}
                </Stack> :
                <Button disabled={disabled || Boolean(itemCount)} onClick={(e) => {
                    addItem({...item, currency: "GBP"});
                    e.stopPropagation();
                }}>{itemCount ? "Added to cart" : "Add to cart"}</Button>}
        </Box>
    );
}
