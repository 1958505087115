import {Box, Button, Chip, Grid, ListItemAvatar, ListItemButton, ListItemSecondaryAction, ListItemText, Stack, Typography, capitalize} from "@mui/material";
import FilterList from "../../Components/FilterList";
import Page from "../../Components/Page";
import {useContext, useEffect, useState} from "react";
import {MerchItem, MusicItem} from "../../Util/typeDefinitions";
import {deleteDoc, doc} from "firebase/firestore";
import {db} from "../../Util/firebase";
import {Popup} from "../../Components/Popup";
import {formatPrice} from "../../Util/util";
import UploadMusicForm from "./Forms/UploadMusicForm";
import IconButtonPop from "../../Components/IconButtonPop";
import {Delete} from "@mui/icons-material";
import {LoadingButton} from "../../Components/LoadingButton";
import UploadMerchForm from "./Forms/UploadMerchForm";
import {AdminContext} from "./AdminHandler";


export default function Items() {
    return (
        <Page title="Items" grid>
            <RenderItemTable type="merch"/>
            <RenderItemTable type="music"/>
        </Page>
    );
}


function RenderItemTable({type}:{type: "music"|"merch"}) {
    const [renderedItems, setRenderedItems] = useState<(false | JSX.Element)[]>();
    const [addItemPopup, setAddItemPopup] = useState(false);
    const [selectedItem, setSelectedItem] = useState<string>();
    const [deleteItemPopup, setDeleteItemPopup] = useState<MusicItem&MerchItem>();

    const items = useContext(AdminContext)[type] || {};

    useEffect(() => {
        setRenderedItems(Object.entries(items).map(([key, item]) => {
            return (
                <ListItemButton key={key} divider id={key} onClick={() => {
                    setSelectedItem(key);
                    setAddItemPopup(true);
                }}>
                    <ListItemAvatar>
                        <Box component={"img"} height={"50px"} src={type === "music" ? item.image : item.images?.[0]?.url}/>
                    </ListItemAvatar>
                    <ListItemText
                        primary={<Stack spacing={0}>
                            <strong>{item.name}</strong>
                        </Stack>}
                        secondary={`${Object.keys((type === "merch" ? item.sizes : item.songs) || {}).length} ${type === "merch" ? "sizes" : "songs"} | ${formatPrice(item.price)} album`}/>
                    <ListItemSecondaryAction>
                        <Chip variant={item.published ? "outlined" : undefined} color={item.published ? "primary" : undefined} label={item.published ? "Published" : "Draft"}/>
                        <IconButtonPop responsive={false} onClick={(e) => {
                            setDeleteItemPopup(item);
                            e.stopPropagation();
                        }} title="Delete item"><Delete/></IconButtonPop>
                    </ListItemSecondaryAction>
                </ListItemButton>
            );
        }).filter((x) => x));
    }, [items]);

    return (
        <>
            <Grid item xs={12} md={8} height={"500px"} m={1}>
                <FilterList key={type} sx={{minHeight: "100%"}} data={items} children={renderedItems} title={<Stack direction={"row"} justifyContent={"space-between"}><Typography variant="h5">{capitalize(type)}</Typography><Button onClick={() => {
                    setSelectedItem(undefined);
                    setAddItemPopup(true);
                }}>Add new {type}</Button></Stack>}/>
            </Grid>
            <Popup key={"addItemPopup"} title={`${selectedItem ? "Edit" : "Upload new"} ${type}`} fullWidth open={addItemPopup} onClose={() => {
                setAddItemPopup(false);
                setSelectedItem(undefined);
            }}>
                {type === "music" ?
                    <UploadMusicForm id={selectedItem} item={items?.[selectedItem || ""] as MusicItem} onComplete={() => setAddItemPopup(false)}/> :
                    <UploadMerchForm id={selectedItem} item={items?.[selectedItem || ""] as MerchItem} onComplete={() => setAddItemPopup(false)}/>
                }
            </Popup>
            <Popup key={"deleteItemPopup"} open={Boolean(deleteItemPopup)} onClose={() => setDeleteItemPopup(undefined)}>
                <Typography variant="h6">Delete album: {deleteItemPopup?.name}</Typography>
                <LoadingButton onClick={() => deleteDoc(doc(db, type, deleteItemPopup?.id.split("-")[1] || "")).then(() => setDeleteItemPopup(undefined))} text="Delete"/>
            </Popup>
        </>
    );
}
