import {Routes, Route, Navigate} from "react-router-dom";
import CookieConsent from "react-cookie-consent";
import {createContext, useEffect, useState} from "react";
import NavBar from "./Components/NavBar";
import {Box} from "@mui/material";
import Home from "./Pages/Home";
import About from "./Pages/About";
import Stream from "./Pages/Stream";
import Gigs from "./Pages/Gigs";
import MerchItem from "./Pages/MerchItem";
import Success from "./Pages/Success";
import Cancel from "./Pages/Cancel";
import Store from "./Pages/Store";
import MusicItem from "./Pages/MusicItem";
import {PRIMARY_COLOR} from ".";
import {getImage, useCheckMobileScreen} from "./Util/util";
import {auth, db, storage} from "./Util/firebase";
import Login from "./Pages/Admin/Login";
import {User, onAuthStateChanged} from "firebase/auth";
import NotFound from "./Pages/NotFound";
import AdminHandler from "./Pages/Admin/AdminHandler";
import {MerchItem as MerchItemType, MusicItem as MusicItemType} from "./Util/typeDefinitions";
import {collection, onSnapshot, query, where} from "firebase/firestore";
import {getDownloadURL, listAll, ref} from "firebase/storage";
import Order from "./Pages/Order";
import ViewBasket, {CheckoutHandler, PaymentHandler} from "./Pages/Checkout";

export const PublicContext = createContext<{merch: {[key: string]: MerchItemType}, music: {[key: string]: MusicItemType}}>({music: {}, merch: {}});

export default function App() {
    const isMobile = useCheckMobileScreen();
    const [user, setUser] = useState<User|null>();

    const [merch, setMerch] = useState<{[key: string]: MerchItemType}>({});
    const [music, setMusic] = useState<{[key: string]: MusicItemType}>({});

    const isDev = window.location.href.startsWith("http://localhost");

    useEffect(() => {
        onAuthStateChanged(auth, (aUser) => {
            setUser(aUser || null);
        });


        const musicQuery = isDev ? query(collection(db, "music")) : query(collection(db, "music"), where("published", "==", true));
        onSnapshot(musicQuery, async (items) => {
            const itemDocs = Object.fromEntries(await Promise.all(items.docs.map(async (item) => {
                const image = await getImage(["music", item.id, "cover.webp"]);
                const album = await getDownloadURL(ref(storage, `music/${item.id}/album.zip`)).catch(() => undefined);

                return [item.id, {...item.data(), id: `music-${item.id}`, image: image, album: album}];
            }))) as {[key: string]: MusicItemType};
            setMusic(itemDocs);
        });
        const merchQuery = isDev ? query(collection(db, "merch")) : query(collection(db, "merch"), where("published", "==", true));
        onSnapshot(merchQuery, async (items) => {
            const itemDocs = Object.fromEntries(await Promise.all(items.docs.map(async (item) => {
                const titleImageObj = (await listAll(ref(storage, `merch/${item.id}`))).items[0];

                return [item.id, {...item.data(), id: `merch-${item.id}`, image: await getDownloadURL(titleImageObj)}];
            }))) as {[key: string]: MerchItemType};
            setMerch(itemDocs);
        });
    }, []);

    if (user === undefined) {
        return null;
    }

    return (
        <Box flexDirection={"column"} flex={1}>
            <PublicContext.Provider value={{merch: merch, music: music}}>
                <NavBar>
                    <Routes>
                        <Route path="admin/*" element={user ? <AdminHandler/> : <Navigate to={"/login"}/>}/>
                        <Route path="login" element={user ? <Navigate to={"/admin/home"}/> : <Login/>}/>
                        <Route path="/" element={<Home/>}/>
                        <Route path="/home" element={<Home/>}/>
                        <Route path="merch/:id" element={<MerchItem/>}/>
                        <Route path="merch/:id/:size" element={<MerchItem/>}/>
                        <Route path="store" element={<Store/>}/>
                        <Route path="store/:type" element={<Store/>}/>
                        <Route path="music/:id" element={<MusicItem/>}/>
                        <Route path="basket" element={<ViewBasket/>}/>
                        <Route path="checkout" element={<CheckoutHandler/>}/>
                        <Route path="about" element={<About/>}/>
                        <Route path="stream" element={<Stream/>}/>
                        <Route path="payment" element={<PaymentHandler/>}/>
                        <Route path="order/:id" element={<Order/>}/>
                        <Route path="gigs" element={<Gigs/>}/>
                        <Route path="success/:id" element={<Success/>}/>
                        <Route path="cancel" element={<Cancel/>}/>
                        <Route path="*" element={<NotFound/>}/>
                    </Routes>
                </NavBar>
            </PublicContext.Provider>
            <CookieConsent style={{textAlign: "left", background: "black", color: "white", borderTop: "1px solid white", marginBottom: isMobile ? "60px" : undefined, alignItems: "center"}} contentStyle={{flex: 1}} buttonStyle={{width: "max-content", padding: "10px", borderRadius: "20px", background: PRIMARY_COLOR, color: "black"}}>
                This website uses cookies to enhance the user experience.
            </CookieConsent>
        </Box>
    );
}
