import {httpsCallable} from "firebase/functions";
import {useEffect, useState} from "react";
import {functions, storage} from "./firebase";
// import {MerchItem, MerchPageItem} from "./typeDefinitions";
import {getDownloadURL, ref, uploadBytes} from "firebase/storage";
import {Grid, TableCell, styled} from "@mui/material";
import {CartDetails} from "use-shopping-cart/core";

export const useCheckMobileScreen = () => {
    const [width, setWidth] = useState(window.innerWidth);
    const handleWindowSizeChange = () => {
        setWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener("resize", handleWindowSizeChange);
        return () => {
            window.removeEventListener("resize", handleWindowSizeChange);
        };
    }, []);

    return (width <= 540);
};


function getWindowDimensions() {
    const {innerWidth: width, innerHeight: height} = window;
    return {
        width,
        height,
    };
}

export default function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowDimensions;
}

export const executeCallable = async (functionName:string, data: unknown) => {
    const callable = httpsCallable(functions, functionName);
    return await callable(data);
};
/*
export const formatMerchItems = async (items: {[key: string]: MerchItem}):Promise<{[key:string]: MerchPageItem}> => {
    const itemsArray = Object.entries(items);

    const finalItems: [string, MerchPageItem][] = await itemsArray.reduce(async (acc, [key, item]) => {
        const image = await getImage(["merch", item.id, `${item.imageOrder?.[0]}.webp`]);

        const finalItem = {...item, image: image} as MerchPageItem;
        if (item.sizes) {
            finalItem.sizes = Object.values((item as MerchItem).sizes || {}).reduce((acc, variant) => {
                acc[variant.name] = variant.stock > 0;
                return acc;
            }, {} as {[size: string]: boolean});
        }
        (await acc).push([key, finalItem]);
        return acc;
    }, Promise.resolve<[string, MerchPageItem][]>([]));

    return Object.fromEntries(finalItems);
};
*/
export const uploadFile = async (path: string[], file: File) => {
    const storageRef = ref(storage, path.join("/"));
    return await uploadBytes(storageRef, file);
};

export const getImage = async (path: string[]) => {
    return await getDownloadURL(ref(storage, path.join("/"))).catch(() => console.log(`Cannot find: ${path.join("/")}`));
};

export const hasMerch = (cartDetails?: CartDetails) => {
    if (!cartDetails) return;
    return Object.keys(cartDetails).some((key) => key.split("-")[0] === "merch");
};

export const formatPrice = (price: number) => {
    const pricefromPence = (price/100).toString();
    const pounds = pricefromPence.split(".")?.[0];
    const pence = pricefromPence.split(".")?.[1];

    const penceFormatted = pence ? pence.length === 2 ? pence : pence + "0" : "00";
    return `£${pounds}.${penceFormatted}`;
};

export const camelCaseToNormal = (string="") => {
    return string.replace(/([A-Z])/g, " $1").toLowerCase().trim().replace(/^./, function(str) {
        return str.toUpperCase();
    });
};

export const getRandomNumber = (min = 0, max:number) => {
    return Math.random() * (max - min) + min;
};

export const snakeCase = (s="") => {
    const nS = s.toString().replace(" ", "_");
    return nS.toLowerCase();
};

export const HoverItem = styled(Grid)`
    cursor: pointer;
    transition: all 100ms ease-in-out;
    &:hover {
        scale: 1.025;
    }
`;


export const SuccessTableCell = styled(TableCell)`
    padding-left: 0;
    padding-right: 4;
`;

export const countries:
    {[key: string]: "UK mainland"|"European"|"Worldwide"} = {
        "AF": "Worldwide",
        "AX": "European",
        "AL": "European",
        "DZ": "Worldwide",
        "AD": "European",
        "AO": "Worldwide",
        "AI": "Worldwide",
        "AQ": "Worldwide",
        "AG": "Worldwide",
        "AR": "Worldwide",
        "AM": "Worldwide",
        "AW": "Worldwide",
        "AC": "Worldwide",
        "AU": "Worldwide",
        "AT": "European",
        "AZ": "Worldwide",
        "BS": "Worldwide",
        "BH": "Worldwide",
        "BD": "Worldwide",
        "BB": "Worldwide",
        "BY": "European",
        "BE": "European",
        "BZ": "Worldwide",
        "BJ": "Worldwide",
        "BM": "Worldwide",
        "BT": "Worldwide",
        "BO": "Worldwide",
        "BA": "European",
        "BW": "Worldwide",
        "BV": "Worldwide",
        "BR": "Worldwide",
        "IO": "Worldwide",
        "VG": "Worldwide",
        "BN": "Worldwide",
        "BG": "European",
        "BF": "Worldwide",
        "BI": "Worldwide",
        "KH": "Worldwide",
        "CM": "Worldwide",
        "CA": "Worldwide",
        "CV": "Worldwide",
        "BQ": "Worldwide",
        "KY": "Worldwide",
        "CF": "Worldwide",
        "TD": "Worldwide",
        "CL": "Worldwide",
        "CN": "Worldwide",
        "CO": "Worldwide",
        "KM": "Worldwide",
        "CG": "Worldwide",
        "CD": "Worldwide",
        "CK": "Worldwide",
        "CR": "Worldwide",
        "CI": "Worldwide",
        "HR": "European",
        "CW": "Worldwide",
        "CY": "European",
        "CZ": "European",
        "DK": "European",
        "DJ": "Worldwide",
        "DM": "Worldwide",
        "DO": "Worldwide",
        "EC": "Worldwide",
        "EG": "Worldwide",
        "SV": "Worldwide",
        "GQ": "Worldwide",
        "ER": "Worldwide",
        "EE": "European",
        "SZ": "Worldwide",
        "ET": "Worldwide",
        "FK": "Worldwide",
        "FO": "Worldwide",
        "FJ": "Worldwide",
        "FI": "European",
        "FR": "European",
        "GF": "Worldwide",
        "PF": "Worldwide",
        "TF": "Worldwide",
        "GA": "Worldwide",
        "GM": "Worldwide",
        "GE": "Worldwide",
        "DE": "European",
        "GH": "Worldwide",
        "GI": "European",
        "GR": "European",
        "GL": "Worldwide",
        "GD": "Worldwide",
        "GP": "Worldwide",
        "GU": "Worldwide",
        "GT": "Worldwide",
        "GG": "Worldwide",
        "GN": "Worldwide",
        "GW": "Worldwide",
        "GY": "Worldwide",
        "HT": "Worldwide",
        "HN": "Worldwide",
        "HK": "Worldwide",
        "HU": "European",
        "IS": "European",
        "IN": "Worldwide",
        "ID": "Worldwide",
        "IQ": "Worldwide",
        "IE": "European",
        "IM": "Worldwide",
        "IL": "Worldwide",
        "IT": "European",
        "JM": "Worldwide",
        "JP": "Worldwide",
        "JE": "Worldwide",
        "JO": "Worldwide",
        "KZ": "Worldwide",
        "KE": "Worldwide",
        "KI": "Worldwide",
        "XK": "European",
        "KW": "Worldwide",
        "KG": "Worldwide",
        "LA": "Worldwide",
        "LV": "European",
        "LB": "Worldwide",
        "LS": "Worldwide",
        "LR": "Worldwide",
        "LY": "Worldwide",
        "LI": "European",
        "LT": "European",
        "LU": "European",
        "MO": "Worldwide",
        "MG": "Worldwide",
        "MW": "Worldwide",
        "MY": "Worldwide",
        "MV": "Worldwide",
        "ML": "Worldwide",
        "MT": "European",
        "MQ": "Worldwide",
        "MR": "Worldwide",
        "MU": "Worldwide",
        "YT": "Worldwide",
        "MX": "Worldwide",
        "MD": "European",
        "MC": "European",
        "MN": "Worldwide",
        "ME": "European",
        "MS": "Worldwide",
        "MA": "Worldwide",
        "MZ": "Worldwide",
        "MM": "Worldwide",
        "NA": "Worldwide",
        "NR": "Worldwide",
        "NP": "Worldwide",
        "NL": "European",
        "NC": "Worldwide",
        "NZ": "Worldwide",
        "NI": "Worldwide",
        "NE": "Worldwide",
        "NG": "Worldwide",
        "NU": "Worldwide",
        "MK": "European",
        "NO": "European",
        "OM": "Worldwide",
        "PK": "Worldwide",
        "PS": "Worldwide",
        "PA": "Worldwide",
        "PG": "Worldwide",
        "PY": "Worldwide",
        "PE": "Worldwide",
        "PH": "Worldwide",
        "PN": "Worldwide",
        "PL": "European",
        "PT": "European",
        "PR": "Worldwide",
        "QA": "Worldwide",
        "RE": "Worldwide",
        "RO": "European",
        "RU": "European",
        "RW": "Worldwide",
        "WS": "Worldwide",
        "SM": "European",
        "ST": "Worldwide",
        "SA": "Worldwide",
        "SN": "Worldwide",
        "RS": "European",
        "SC": "Worldwide",
        "SL": "Worldwide",
        "SG": "Worldwide",
        "SX": "Worldwide",
        "SK": "European",
        "SI": "European",
        "SB": "Worldwide",
        "SO": "Worldwide",
        "ZA": "Worldwide",
        "GS": "Worldwide",
        "KR": "Worldwide",
        "SS": "Worldwide",
        "ES": "European",
        "LK": "Worldwide",
        "BL": "Worldwide",
        "SH": "Worldwide",
        "KN": "Worldwide",
        "LC": "Worldwide",
        "MF": "Worldwide",
        "PM": "Worldwide",
        "VC": "Worldwide",
        "SD": "Worldwide",
        "SR": "Worldwide",
        "SJ": "Worldwide",
        "SE": "European",
        "CH": "European",
        "TW": "Worldwide",
        "TJ": "Worldwide",
        "TZ": "Worldwide",
        "TH": "Worldwide",
        "TL": "Worldwide",
        "TG": "Worldwide",
        "TK": "Worldwide",
        "TO": "Worldwide",
        "TT": "Worldwide",
        "TA": "Worldwide",
        "TN": "Worldwide",
        "TR": "European",
        "TM": "Worldwide",
        "TC": "Worldwide",
        "TV": "Worldwide",
        "UG": "Worldwide",
        "UA": "European",
        "AE": "Worldwide",
        "GB": "UK mainland",
        "US": "Worldwide",
        "UY": "Worldwide",
        "UZ": "Worldwide",
        "VU": "Worldwide",
        "VA": "European",
        "VE": "Worldwide",
        "VN": "Worldwide",
        "WF": "Worldwide",
        "EH": "Worldwide",
        "YE": "Worldwide",
        "ZM": "Worldwide",
        "ZW": "Worldwide",
    };

export const countryNames: {[key: string]: string} = {
    "AF": "Afghanistan",
    "AX": "Åland Islands",
    "AL": "Albania",
    "DZ": "Algeria",
    "AD": "Andorra",
    "AO": "Angola",
    "AI": "Anguilla",
    "AQ": "Antarctica",
    "AG": "Antigua & Barbuda",
    "AR": "Argentina",
    "AM": "Armenia",
    "AW": "Aruba",
    "AC": "Ascension Island",
    "AU": "Australia",
    "AT": "Austria",
    "AZ": "Azerbaijan",
    "BS": "Bahamas",
    "BH": "Bahrain",
    "BD": "Bangladesh",
    "BB": "Barbados",
    "BY": "Belarus",
    "BE": "Belgium",
    "BZ": "Belize",
    "BJ": "Benin",
    "BM": "Bermuda",
    "BT": "Bhutan",
    "BO": "Bolivia",
    "BA": "Bosnia & Herzegovina",
    "BW": "Botswana",
    "BV": "Bouvet Island",
    "BR": "Brazil",
    "IO": "British Indian Ocean Territory",
    "VG": "British Virgin Islands",
    "BN": "Brunei",
    "BG": "Bulgaria",
    "BF": "Burkina Faso",
    "BI": "Burundi",
    "KH": "Cambodia",
    "CM": "Cameroon",
    "CA": "Canada",
    "CV": "Cape Verde",
    "BQ": "Caribbean Netherlands",
    "KY": "Cayman Islands",
    "CF": "Central African Republic",
    "TD": "Chad",
    "CL": "Chile",
    "CN": "China",
    "CO": "Colombia",
    "KM": "Comoros",
    "CG": "Congo - Brazzaville",
    "CD": "Congo - Kinshasa",
    "CK": "Cook Islands",
    "CR": "Costa Rica",
    "CI": "Côte d’Ivoire",
    "HR": "Croatia",
    "CW": "Curaçao",
    "CY": "Cyprus",
    "CZ": "Czechia",
    "DK": "Denmark",
    "DJ": "Djibouti",
    "DM": "Dominica",
    "DO": "Dominican Republic",
    "EC": "Ecuador",
    "EG": "Egypt",
    "SV": "El Salvador",
    "GQ": "Equatorial Guinea",
    "ER": "Eritrea",
    "EE": "Estonia",
    "SZ": "Eswatini",
    "ET": "Ethiopia",
    "FK": "Falkland Islands",
    "FO": "Faroe Islands",
    "FJ": "Fiji",
    "FI": "Finland",
    "FR": "France",
    "GF": "French Guiana",
    "PF": "French Polynesia",
    "TF": "French Southern Territories",
    "GA": "Gabon",
    "GM": "Gambia",
    "GE": "Georgia",
    "DE": "Germany",
    "GH": "Ghana",
    "GI": "Gibraltar",
    "GR": "Greece",
    "GL": "Greenland",
    "GD": "Grenada",
    "GP": "Guadeloupe",
    "GU": "Guam",
    "GT": "Guatemala",
    "GG": "Guernsey",
    "GN": "Guinea",
    "GW": "Guinea-Bissau",
    "GY": "Guyana",
    "HT": "Haiti",
    "HN": "Honduras",
    "HK": "Hong Kong SAR China",
    "HU": "Hungary",
    "IS": "Iceland",
    "IN": "India",
    "ID": "Indonesia",
    "IQ": "Iraq",
    "IE": "Ireland",
    "IM": "Isle of Man",
    "IL": "Israel",
    "IT": "Italy",
    "JM": "Jamaica",
    "JP": "Japan",
    "JE": "Jersey",
    "JO": "Jordan",
    "KZ": "Kazakhstan",
    "KE": "Kenya",
    "KI": "Kiribati",
    "XK": "Kosovo",
    "KW": "Kuwait",
    "KG": "Kyrgyzstan",
    "LA": "Laos",
    "LV": "Latvia",
    "LB": "Lebanon",
    "LS": "Lesotho",
    "LR": "Liberia",
    "LY": "Libya",
    "LI": "Liechtenstein",
    "LT": "Lithuania",
    "LU": "Luxembourg",
    "MO": "Macao SAR China",
    "MG": "Madagascar",
    "MW": "Malawi",
    "MY": "Malaysia",
    "MV": "Maldives",
    "ML": "Mali",
    "MT": "Malta",
    "MQ": "Martinique",
    "MR": "Mauritania",
    "MU": "Mauritius",
    "YT": "Mayotte",
    "MX": "Mexico",
    "MD": "Moldova",
    "MC": "Monaco",
    "MN": "Mongolia",
    "ME": "Montenegro",
    "MS": "Montserrat",
    "MA": "Morocco",
    "MZ": "Mozambique",
    "MM": "Myanmar (Burma)",
    "NA": "Namibia",
    "NR": "Nauru",
    "NP": "Nepal",
    "NL": "Netherlands",
    "NC": "New Caledonia",
    "NZ": "New Zealand",
    "NI": "Nicaragua",
    "NE": "Niger",
    "NG": "Nigeria",
    "NU": "Niue",
    "MK": "North Macedonia",
    "NO": "Norway",
    "OM": "Oman",
    "PK": "Pakistan",
    "PS": "Palestinian Territories",
    "PA": "Panama",
    "PG": "Papua New Guinea",
    "PY": "Paraguay",
    "PE": "Peru",
    "PH": "Philippines",
    "PN": "Pitcairn Islands",
    "PL": "Poland",
    "PT": "Portugal",
    "PR": "Puerto Rico",
    "QA": "Qatar",
    "RE": "Réunion",
    "RO": "Romania",
    "RU": "Russia",
    "RW": "Rwanda",
    "WS": "Samoa",
    "SM": "San Marino",
    "ST": "São Tomé & Príncipe",
    "SA": "Saudi Arabia",
    "SN": "Senegal",
    "RS": "Serbia",
    "SC": "Seychelles",
    "SL": "Sierra Leone",
    "SG": "Singapore",
    "SX": "Sint Maarten",
    "SK": "Slovakia",
    "SI": "Slovenia",
    "SB": "Solomon Islands",
    "SO": "Somalia",
    "ZA": "South Africa",
    "GS": "South Georgia & South Sandwich Islands",
    "KR": "South Korea",
    "SS": "South Sudan",
    "ES": "Spain",
    "LK": "Sri Lanka",
    "BL": "St. Barthélemy",
    "SH": "St. Helena",
    "KN": "St. Kitts & Nevis",
    "LC": "St. Lucia",
    "MF": "St. Martin",
    "PM": "St. Pierre & Miquelon",
    "VC": "St. Vincent & Grenadines",
    "SD": "Sudan",
    "SR": "Suriname",
    "SJ": "Svalbard & Jan Mayen",
    "SE": "Sweden",
    "CH": "Switzerland",
    "TW": "Taiwan",
    "TJ": "Tajikistan",
    "TZ": "Tanzania",
    "TH": "Thailand",
    "TL": "Timor-Leste",
    "TG": "Togo",
    "TK": "Tokelau",
    "TO": "Tonga",
    "TT": "Trinidad & Tobago",
    "TA": "Tristan da Cunha",
    "TN": "Tunisia",
    "TR": "Türkiye",
    "TM": "Turkmenistan",
    "TC": "Turks & Caicos Islands",
    "TV": "Tuvalu",
    "UG": "Uganda",
    "UA": "Ukraine",
    "AE": "United Arab Emirates",
    "GB": "United Kingdom",
    "US": "United States",
    "UY": "Uruguay",
    "UZ": "Uzbekistan",
    "VU": "Vanuatu",
    "VA": "Vatican City",
    "VE": "Venezuela",
    "VN": "Vietnam",
    "WF": "Wallis & Futuna",
    "EH": "Western Sahara",
    "YE": "Yemen",
    "ZM": "Zambia",
    "ZW": "Zimbabwe",
};
