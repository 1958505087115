import {Routes, Route} from "react-router-dom";
import Admin from "./Admin";
import Orders from "./Orders";
import Items from "./Items";
import Analytics from "./Analytics";
import Money from "./Money";
import {createContext, useEffect, useState} from "react";
import {MerchItem, MusicItem, Order} from "../../Util/typeDefinitions";
import {collection, onSnapshot, query} from "firebase/firestore";
import {db, storage} from "../../Util/firebase";
import {getDownloadURL, listAll, ref} from "firebase/storage";

export const AdminContext = createContext<{music: {[key: string]: MusicItem}|undefined, merch: {[key: string]: MerchItem}|undefined, orders: {[key: string]: Order}|undefined}>({music: {}, merch: {}, orders: {}});

export default function AdminHandler() {
    const [music, setMusic] = useState<{[key: string]: MusicItem}>();
    const [merch, setMerch] = useState<{[key: string]: MerchItem}>();
    const [orders, setOrders] = useState<{[key: string]: Order}>();

    useEffect(() => {
        onSnapshot(collection(db, "music"), async (d) => {
            const formattedDocs: {[key: string]: MusicItem} = Object.fromEntries(await Promise.all(d.docs.map(async (doc) => {
                const coverImage = await getDownloadURL(ref(storage, `music/${doc.id}/cover.webp`)).catch(() => undefined);
                const album = await getDownloadURL(ref(storage, `music/${doc.id}/album.zip`)).catch(() => undefined);

                const finalData: MusicItem = {...doc.data() as MusicItem, id: `music-${doc.id}`, image: coverImage, album: album};

                if (finalData.songs) {
                    finalData.songs = Object.fromEntries(await Promise.all(Object.entries(finalData.songs).map(async ([key, song]) => {
                        const image = await getDownloadURL(ref(storage, `music/${doc.id}/${song.position}.webp`)).catch(() => undefined);
                        const songFile = await getDownloadURL(ref(storage, `music/${doc.id}/${song.position}.wav`)).catch(() => undefined);

                        return [key, {...song, image: image, songFile: songFile}];
                    })));
                }
                return [doc.id, finalData];
            })));
            setMusic(formattedDocs);
        });

        onSnapshot(collection(db, "merch"), async (d) => {
            const formattedDocs: {[key: string]: MerchItem} = Object.fromEntries(await Promise.all(d.docs.map(async (doc) => {
                // Find all the prefixes and items.
                let images = await Promise.all((await listAll(ref(storage, `merch/${doc.id}`))).items.map(async (item) => ({name: item.name, url: await getDownloadURL(item)})));

                images = images.sort((a, b) => doc.data().imageOrder.indexOf(parseInt(a.name.split(".")[0])) - doc.data().imageOrder.indexOf(parseInt(b.name.split(".")[0])));

                const finalData:MerchItem = {...doc.data() as MerchItem, id: `merch-${doc.id}`, images: images};

                return [doc.id, finalData];
            })));

            setMerch(formattedDocs);
        });

        onSnapshot(query(collection(db, "orders")), (d) => {
            const formattedDocs = Object.fromEntries(d.docs.map((doc) => [doc.id, doc.data() as Order]));
            setOrders(formattedDocs);
        });
    }, []);

    return (
        <AdminContext.Provider value={{music: music, merch: merch, orders: orders}}>
            <Routes>
                <Route path="home" element={<Admin/>}/>
                <Route path="orders" element={<Orders/>}/>
                <Route path="items" element={<Items/>}/>
                <Route path="analytics" element={ <Analytics/>}/>
                <Route path="money" element={<Money/>}/>
            </Routes>
        </AdminContext.Provider>
    );
}
